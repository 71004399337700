import React, { useEffect, useMemo, useState } from 'react';
import r from '../http';
import { VendingMachine, VendingMachineResponse } from '@models/VendingMachine';

interface State {
	vendingMachines: VendingMachine[];
	isFetchingVendingMachinesStatus: boolean;
	setVendingMachines: (vendingMachines: VendingMachine[]) => void;
	addVendingMachine: (vendingMachine: VendingMachine) => void;
	updateVendingMachine: (vendingMachine: VendingMachine) => void;
	deleteVendingMachine: (id: number) => void;
	registerVendingMachine: (id: number) => Promise<void>;
	unregisterVendingMachine: (id: number) => Promise<void>;
}

const initialState: State = {
	vendingMachines: [],
	isFetchingVendingMachinesStatus: false,
	setVendingMachines: () => {},
	addVendingMachine: () => {},
	updateVendingMachine: () => {},
	deleteVendingMachine: () => {},
	registerVendingMachine: async () => {},
	unregisterVendingMachine: async () => {},
};

const VendingMachineContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const VendingMachineContextProvider = ({ children }: Props) => {
	const [vendingMachines, setVendingMachines] = useState<VendingMachine[]>([]);
	const [isFetchingVendingMachinesStatus, setIsFetchingVendingMachinesStatus] = useState<boolean>(
		initialState.isFetchingVendingMachinesStatus
	);

	useEffect(() => {
		(async () => {
			const vendingMachineResponse = await r.get<VendingMachineResponse[]>('/admin/vending-machines');
			setVendingMachines(
				vendingMachineResponse.data.map((vendingMachine) => ({
					...vendingMachine,
					online: null,
				}))
			);

			setIsFetchingVendingMachinesStatus(true);

			const query = vendingMachineResponse.data
				.map((vendingMachine, i) => {
					const prefix = i === 0 ? '?' : '&';

					return `${prefix}vendingMachineIds=${vendingMachine.id}`;
				})
				.join('');

			try {
				const response = await r.get<{ vendingMachineId: number; isOnline: boolean }[]>(
					`/admin/vending-machines/status${query}`
				);
				setVendingMachines((prev) =>
					prev.map((vendingMachine) => ({
						...vendingMachine,
						online: response.data.find((status) => status.vendingMachineId === vendingMachine.id)?.isOnline ?? null,
					}))
				);
			} catch (e) {}

			setIsFetchingVendingMachinesStatus(false);
		})();
	}, []);

	useEffect(() => {}, [vendingMachines]);

	async function registerVendingMachine(id: number) {
		await r.post(`/admin/vending-machines/${id}/register`);
	}

	async function unregisterVendingMachine(id: number) {
		await r.post(`/admin/vending-machines/${id}/unregister`);
	}

	const value: State = useMemo(() => {
		return {
			vendingMachines,
			isFetchingVendingMachinesStatus,
			setVendingMachines,
			addVendingMachine: (vendingMachine: VendingMachine) => setVendingMachines(vendingMachines.concat(vendingMachine)),
			updateVendingMachine: (vendingMachine: VendingMachine) =>
				setVendingMachines(vendingMachines.map((v) => (v.id === vendingMachine.id ? vendingMachine : v))),
			deleteVendingMachine: (id: number) => setVendingMachines(vendingMachines.filter((p) => p.id !== id)),
			registerVendingMachine,
			unregisterVendingMachine,
		};
	}, [vendingMachines]);

	return <VendingMachineContext.Provider value={value}>{children}</VendingMachineContext.Provider>;
};

export default VendingMachineContext;
