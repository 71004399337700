import styles from './styles/CreateVendingMachineScreen.module.scss';
import { Button, Card, Divider, Form, Input, Select, Space, notification, InputNumber } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import r from '../../http';
import PageContainer from '../../components/PageContainer';
import VendingMachineContext from '../../state/VendingMachine';
import { VendingMachine } from '../../models/VendingMachine';
import StoreContext from '../../state/Store';
import { typeOptions } from '../utils';

const CreateVendingMachineScreen = () => {
	const { stores } = useContext(StoreContext);
	const { addVendingMachine } = useContext(VendingMachineContext);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const navigate = useNavigate();
	const [form] = Form.useForm();

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	const submit = async (form: any) => {
		const body = new FormData();
		body.append('name', form.name);
		body.append('type', form.type);
		body.append('storeId', form.store || '0');
		body.append('deviceId', form.deviceId);
		body.append('latitude', form.latitude);
		body.append('longitude', form.longitude);
		body.append('maxProductsPerUserPerHour', form.maxProductsPerUserPerHour);
		try {
			setIsSubmitting(true);
			const response = await r.post<VendingMachine>('/admin/vending-machines', body);
			addVendingMachine(response.data);
			notification.success({
				message: 'Tobaksautomat skapad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	return (
		<PageContainer back title="Skapa tobaksautomat">
			<Form {...layout} labelAlign="left" form={form} size="large" onFinish={submit}>
				<Card>
					<Form.Item label="Namn" name="name" rules={[{ required: true, message: 'Vänligen ange namn' }]}>
						<Input placeholder="Namn" />
					</Form.Item>
					<Divider />
					<Form.Item label="Enhets ID" name="deviceId" rules={[{ required: true, message: 'Vänligen ange enhets ID' }]}>
						<Input placeholder="Enhets ID" />
					</Form.Item>
					<Divider />
					<Form.Item
						extra="Detta värdet används bara för äldre appversioner när tobaksautomater var kopplade direkt till butiken"
						label="Butik"
						name="store"
						getValueFromEvent={(e) => e.value}
					>
						<Select labelInValue placeholder="Butik" allowClear style={{ width: 200 }}>
							{stores.map((store) => (
								<Select.Option key={store.id} title={store.name} value={store.id}>
									{store.name}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Divider />
					<Form.Item label="Typ" name="type" rules={[{ required: true, message: 'Vänligen välj type' }]}>
						<Select placeholder="Typ" allowClear style={{ width: 200 }}>
							{typeOptions.map((typeOption) => (
								<Select.Option key={typeOption.value} title={typeOption.label} value={typeOption.value}>
									{typeOption.label}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Divider />
					<Form.Item label="Latitude" name="latitude" rules={[{ required: true, message: 'Vänligen ange latitude' }]}>
						<Input placeholder="Latitude" />
					</Form.Item>
					<Divider />
					<Form.Item
						label="Longitude"
						name="longitude"
						rules={[{ required: true, message: 'Vänligen ange longitude' }]}
					>
						<Input placeholder="Longitude" />
					</Form.Item>
					<Divider />
					<Form.Item
						label="Max köp per timme"
						name="maxProductsPerUserPerHour"
						rules={[{ required: true, message: 'Vänligen ange max köp per timme' }]}
					>
						<InputNumber style={{ width: 200 }} placeholder="Max köp per timme" />
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<PlusOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Skapa tobaksautomat
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default CreateVendingMachineScreen;
