import Order, { orderProduct } from '../models/Order';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import StoreContext from './Store';
import AuthContext from './Auth';
import ProductContext from './Product';

interface State {
	order: Order;
	totalCost: number;
	setTotalCost: (cost: number) => void;
	setOrderNote: (note: string) => void;
	setOrderStoreId: (storeId: number) => void;
	updateProductList: (productList: orderProduct[]) => void;
	resetOrder: () => void;
}

const initialState: State = {
	order: {
		user: {
			id: -1,
			email: '',
		},
		storeId: null,
		note: '',
		products: [],
	},
	totalCost: 0,
	setTotalCost: () => {},
	setOrderNote: () => {},
	setOrderStoreId: () => {},
	updateProductList: () => {},
	resetOrder: () => {},
};

const OrderContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const OrderContextProvider = ({ children }: Props) => {
	const [order, setOrder] = useState<Order>(initialState.order);
	const [totalCost, setTotalCost] = useState(0);
	const { defaultStore } = useContext(StoreContext);
	const { user } = useContext(AuthContext);
	const [initFetch, setInitFetch] = useState(false);
	const { products } = useContext(ProductContext);

	useEffect(() => {
		let product_storage = localStorage.getItem('products');
		let note_storage = localStorage.getItem('note');
		if (product_storage !== null && note_storage !== null) {
			let prev_products = JSON.parse(product_storage);
			let prev_note = JSON.parse(note_storage);
			setOrder({ ...order, products: prev_products, note: prev_note });
		} else if (product_storage !== null && note_storage === null) {
			let prev_products = JSON.parse(product_storage);
			setOrder({ ...order, products: prev_products });
		} else if (note_storage !== null && product_storage === null) {
			let prev_note = JSON.parse(note_storage);
			setOrder({ ...order, note: prev_note });
		}
		setInitFetch(true);
		return;
	}, []);

	useEffect(() => {
		if (initFetch) {
			localStorage.setItem('products', JSON.stringify(order.products));
			const orderProductList = order.products;
			let cost = 0;
			for (let i = 0; i < orderProductList.length; i++) {
				const p = products.find((_p) => _p.id === orderProductList[i].productId);
				if (!p) return;
				cost = cost + p.inPrice * orderProductList[i].quantity;
			}
			setTotalCost(cost);
		}
	}, [order, products]);

	useEffect(() => {
		if (defaultStore === undefined || !user) return;
		setOrder({ ...order, storeId: defaultStore, user: { id: user.id, email: user.email } });
	}, [defaultStore, user]);

	useEffect(() => {
		if (initFetch) {
			localStorage.setItem('note', JSON.stringify(order.note));
		}
	}, [order.note]);

	const value: State = useMemo(() => {
		return {
			order,
			totalCost,
			setTotalCost: (_cost: number) => setTotalCost(_cost),
			setOrderNote: (_note: string) => setOrder({ ...order, note: _note }),
			updateProductList: (productList: orderProduct[]) => setOrder({ ...order, products: productList }),
			setOrderStoreId: (storeId: number) => setOrder({ ...order, storeId }),
			resetOrder: () => {
				setOrder({ ...order, products: [], note: '' });
				setTotalCost(0);
			},
		};
	}, [order, totalCost]);
	return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
};

export default OrderContext;
