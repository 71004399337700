import styles from './styles/ChangePasswordScreen.module.scss';
import { Button, Form, Input, Result, Typography } from 'antd';
import { LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import logo from '../../assets/images/logo-black.png';
import { Link, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import r from '../../http';
import CenterDiv from '@components/CenterDiv';

const { Text } = Typography;

function ChangePasswordScreen() {
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  async function changePassword(form: any) {
    const email = searchParams.get('email');
    const token = searchParams.get('token');

    if (!email || !token) {
      setErrorMessage('Ogiltig återställningslänk');
      return;
    }

    setLoading(true);
    try {
      await r.post('/admin/auth/change-password', {
        email,
        token,
        newPassword: form.newPassword,
      });
      setShowSuccess(true);
    } catch (e) {
      setErrorMessage('Något gick fel. Försök igen senare.');
    }

    setLoading(false);
  }

  return (
    <CenterDiv>
      <div className={styles.container}>
        <div className={styles.information}>
          {showSuccess ? (
            <Result
              status="success"
              title="Lösenord ändrat"
              subTitle="Du kan nu logga in med ditt nya lösenord"
              extra={[
                <Link to="/login">
                  <Button type="primary" key="console">
                    Logga in
                  </Button>
                </Link>,
              ]}
            />
          ) : (
            <>
              <img className={styles.img} src={logo} alt="logo" />
              <span className={styles.field}>
                <div className={styles.title}>Återställ lösenord</div>
              </span>
              <Form name="login" className="login-form" onFinish={changePassword}>
                <Form.Item
                  name="newPassword"
                  rules={[
                    { required: true, message: 'Vänligen ange nytt lösenord' },
                    { min: 6, message: 'Lösenordet måste vara minst 6 tecken långt' },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Nytt lösenord"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
                <Text type="danger">{errorMessage}</Text>
                <Form.Item>
                  <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                    Ändra lösenord
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </div>
      </div>
    </CenterDiv>
  );
}

export default ChangePasswordScreen;
