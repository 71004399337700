import ProductCategory from '@models/ProductCategory';
import React, { useEffect, useMemo, useState } from 'react';
import r from '../http';

interface State {
  productCategories: ProductCategory[];
  addProductCategory: (productCategory: ProductCategory) => void;
  updateProductCategory: (productCategory: ProductCategory) => void;
  deleteProductCategory: (id: number) => void;
}

const initialState: State = {
  productCategories: [],
  addProductCategory: () => {},
  updateProductCategory: () => {},
  deleteProductCategory: () => {},
};

const ProductCategoryContext = React.createContext<State>(initialState);

interface Props {
  children: React.ReactNode;
}

export const ProductCategoryContextProvider = ({ children }: Props) => {
  const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);

  useEffect(() => {
    (async () => {
      const response = await r.get<ProductCategory[]>('/admin/product-categories');
      setProductCategories(response.data);
    })();
  }, []);

  const value: State = useMemo(() => {
    return {
      productCategories,
      addProductCategory: (productCategory: ProductCategory) => setProductCategories(productCategories.concat(productCategory)),
      updateProductCategory: (productCategory: ProductCategory) => setProductCategories(productCategories.map((p) => (p.id === productCategory.id ? productCategory : p))),
      deleteProductCategory: (id: number) => setProductCategories(productCategories.filter((p) => p.id !== id)),
    };
  }, [productCategories]);

  return <ProductCategoryContext.Provider value={value}>{children}</ProductCategoryContext.Provider>;
};

export default ProductCategoryContext;

