import { Alert, Button, Card, Col, Modal, Row, Space, Tabs } from 'antd';
import { useMemo, useState } from 'react';
import PageContainer from '../components/PageContainer';
import _4inA4 from './components/4inA4Offer/4inA4';
import InstagramOfferPrint from './components/InstagramOffer/InstagramOfferPrint';
import StoreOfferPrint from './components/StoreOffer/StoreOfferPrint';
import StoreOfferPrintWithImage from './components/StoreOfferWithImage/StoreOfferPrintWithImage';
import { QuestionCircleOutlined } from '@ant-design/icons';
import PrintHelp from './components/PrintHelp';

const isMobileOrTablet = () => {
	return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
		navigator.userAgent
	);
};

const NotSupportedMessage = () => (
	<Alert message="Utskrivning av erbjudanden kan endast göras från datorer. " type="info" />
);

const getItems = () => {
	const items = [
		{
			label: 'Reklam A4',
			key: '0',
			children: <StoreOfferPrint />,
		},
		{
			label: 'Reklam A4 - Bild',
			key: '1',
			children: <StoreOfferPrintWithImage />,
		},
		{
			label: 'A4/4',
			key: '2',
			children: <_4inA4 />,
		},
		{
			label: 'Reklam Instagram',
			key: '3',
			children: <InstagramOfferPrint />,
		},
	];

	return items;
};

const OfferPrintScreen = () => {
	const notSupported = useMemo(() => isMobileOrTablet(), []);

	return (
		<PageContainer title="Skriv ut erbjudande">
			<Card>
				<Row>
					<Col xs={0} sm={0} md={24}>
						{notSupported ? (
							<NotSupportedMessage />
						) : (
							<Tabs defaultActiveKey="0" size="large" items={getItems()} tabBarExtraContent={<PrintHelpDialog />} />
						)}
					</Col>
					<Col xs={24} sm={0}>
						<NotSupportedMessage />
					</Col>
				</Row>
			</Card>
		</PageContainer>
	);
};

export default OfferPrintScreen;

function PrintHelpDialog() {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Button onClick={() => setIsOpen(true)} icon={<QuestionCircleOutlined />}>
				Utskriftsinformation
			</Button>
			<Modal
				title="Inställningar för utskrigt av reklamblad"
				open={isOpen}
				okText={null}
				width={700}
				onCancel={() => setIsOpen(false)}
				footer={<Button onClick={() => setIsOpen(false)}>Stäng</Button>}
			>
				<PrintHelp />
			</Modal>
		</>
	);
}
