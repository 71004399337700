import { useContext, useState } from 'react';
import { Button, Spin, Divider, Card, Row, Col, Typography, Form, Space, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import VendingMachineContext from '../../state/VendingMachine';
import StoreContext from '../../state/Store';
import AuthContext from '../../state/Auth';
import VendingMachineProductsView from './components/VendingMachineProductsView';
import styles from './styles/ViewVendingMachineScreen.module.scss';
import Role from '../../models/Role';
import { getTypeLabel } from '../utils';

const { Text } = Typography;

const ViewVendingMachineScreen = () => {
	const [isRegisteringVendingMachine, setIsRegisteringVendingMachine] = useState(false);
	const [isUnregisteringVendingMachine, setIsUnregisteringVendingMachine] = useState(false);
	const { stores } = useContext(StoreContext);
	const { vendingMachines, registerVendingMachine, unregisterVendingMachine } = useContext(VendingMachineContext);
	const { user } = useContext(AuthContext);

	const navigate = useNavigate();
	const id = useParams().id || '0';
	const isAdmin = user?.roles.includes(Role.Admin);

	const vendingMachine = vendingMachines.find((vendingMachine) => vendingMachine.id === parseInt(id));

	async function register() {
		if (!vendingMachine) return;

		setIsRegisteringVendingMachine(true);

		try {
			await registerVendingMachine(vendingMachine.id);

			notification.success({
				message: 'Skåp registrerat.',
				placement: 'bottomRight',
				duration: 5,
			});
		} catch (e) {
			notification.error({
				message: `Vi kunde inte registrera skåpet. Detta kan bero på att skåpet redan är registrerat.`,
				placement: 'bottomRight',
				duration: 10,
			});
		}
		setIsRegisteringVendingMachine(false);
	}

	async function unregister() {
		if (!vendingMachine) return;

		setIsUnregisteringVendingMachine(true);

		try {
			await unregisterVendingMachine(vendingMachine.id);

			notification.success({
				message: 'Skåp avregistrerat.',
				placement: 'bottomRight',
				duration: 5,
			});
		} catch (e) {
			notification.error({
				message: `Vi kunde inte avregistrera skåpet. Detta kan bero på att skåpet redan är avregistrerat.`,
				placement: 'bottomRight',
				duration: 10,
			});
		}
		setIsUnregisteringVendingMachine(false);
	}

	const labelLayout = {
		md: 6,
		sm: 24,
		xs: 24,
	};

	const valueLayout = {
		md: 12,
		sm: 24,
		xs: 24,
	};
	return (
		<PageContainer
			title="Tobaksautomat detaljer"
			back
			extra={[<Button key="edit" onClick={() => navigate('edit')} type="primary" icon={<EditOutlined />} />]}
		>
			<Spin spinning={!vendingMachine} tip="Hämtar tobaksautomat...">
				<Card key={vendingMachine?.id}>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								ID
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{vendingMachine?.id}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Namn
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{vendingMachine?.name}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Typ
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{vendingMachine?.type && getTypeLabel(vendingMachine?.type)}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Butik
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{stores.find((s) => s.id === vendingMachine?.storeId)?.name}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Enhets ID
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{vendingMachine?.deviceId}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Latitude
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{vendingMachine?.latitude}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Longitude
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{vendingMachine?.longitude}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Max köp per timme
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{vendingMachine?.maxProductsPerUserPerHour}</Text>
						</Col>
					</Row>
				</Card>
				<div className={styles.actions}>
					<Space>
						<Button
							type="primary"
							ghost
							htmlType="submit"
							loading={isRegisteringVendingMachine}
							size="large"
							onClick={register}
						>
							Registrera
						</Button>
						<Button
							danger
							ghost
							htmlType="submit"
							loading={isUnregisteringVendingMachine}
							size="large"
							onClick={unregister}
						>
							Avregistrera
						</Button>
					</Space>
				</div>
			</Spin>
			{vendingMachine && isAdmin && <VendingMachineProductsView vendingMachineId={vendingMachine.id} />}
		</PageContainer>
	);
};

export default ViewVendingMachineScreen;
