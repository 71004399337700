import { Token, TokenResponse } from '@models/Token';

export function createTokenFromTokenResponse(tokenResponse: TokenResponse): Token {
  const nowInSeconds = Math.round(Date.now() / 1000);

  const token: Token = {
    accessToken: tokenResponse.accessToken,
    accessTokenExpireTimestamp: nowInSeconds + tokenResponse.accessTokenExpiresInSeconds,
    refreshToken: tokenResponse.refreshToken,
    refreshTokenExpireTimestamp: nowInSeconds + tokenResponse.refreshTokenExpiresInSeconds,
  };

  return token;
}

export const hasTokenExpired = (tokenExpireDateTimestamp: number, bufferPeriod: number = 0): boolean => {
  const currentTimestamp = Math.round(Date.now() / 1000);

  const secondsUntilExpire = tokenExpireDateTimestamp - currentTimestamp;
  if (secondsUntilExpire > bufferPeriod) return false;

  return true;
};
