import PageContainer from '@components/PageContainer';
import { Button, Card, Divider, Form, Input, notification, Select, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import r from '../../http';
import TobaccoProduct from '@models/TobaccoProduct';
import useTobaccoProducts from '@hooks/tobacco-products';
import styles from './styles/CreateTobaccoProductScreen.module.scss';
import useProductCategories from '@hooks/product-category';
import FormImage from '@components/form/Image';

const CreateTobaccoProductScreen = () => {
	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { addTobaccoProduct } = useTobaccoProducts();
	const { productCategories } = useProductCategories();
	const [form] = Form.useForm();

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	const submit = async (form: any) => {
		setIsSubmitting(true);

		try {
			const body = new FormData();

			body.append('name', form.name);
			body.append('image', form.image[0].originFileObj);
			if (form.categoryId) {
				body.append('categoryId', form.categoryId);
			}

			const response = await r.post<TobaccoProduct>('/admin/tobacco-products', body);

			addTobaccoProduct(response.data);

			notification.success({
				message: 'Tobaksprodukt skapad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}

		setIsSubmitting(false);
	};

	return (
		<PageContainer back title="Skapa tobaksprodukt">
			<Form {...layout} labelAlign="left" form={form} size="large" onFinish={submit}>
				<Card>
					<Form.Item label="Namn" name="name" rules={[{ required: true, message: 'Vänligen ange namn' }]}>
						<Input placeholder="Namn" />
					</Form.Item>
					<Divider />
					<FormImage label="Bild" name="image" required />
					<Divider />
					<Form.Item label="Kategori" name="categoryId">
						<Select
							placeholder="Kategori"
							allowClear
							options={productCategories.map((category) => ({
								value: category.id,
								label: category.name,
							}))}
						/>
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<PlusOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Skapa tobaksprodukt
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default CreateTobaccoProductScreen;
