import React, { useContext, useEffect, useState } from 'react';
import User, { FirebaseUser } from '../models/User';
import r from '../http';
import { StorageKey, getStorageItem, removeStorageItem, setStorageItem } from '@helpers/storage';
import useTokenRefresher from '@hooks/token';
import { useSetSentryUser } from '@hooks/sentry';
import FirebaseAuthContext, { AuthStatus as FirebaseAuthStatus } from './FirebaseAuth';
import { Token, TokenResponse } from '@models/Token';
import { createTokenFromTokenResponse, hasTokenExpired } from '@helpers/token';

interface State {
	authReady: boolean;
	authenticated: boolean;
	user: User | null;
	signOut: () => Promise<void>;
	signIn: (email: string, password: string) => Promise<void>;
}

const initialState: State = {
	authReady: false,
	authenticated: false,
	user: null,
	signOut: async () => undefined,
	signIn: async () => undefined,
};

const AuthContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

const getPersistedToken = (): Token | null => getStorageItem<Token>(StorageKey.AuthToken);
const setPersistedToken = (token: Token): void => setStorageItem(StorageKey.AuthToken, token);
const removePersistedToken = (): void => removeStorageItem(StorageKey.AuthToken);

export const AuthContextProvider = ({ children }: Props) => {
	const [authReady, setAuthReady] = useState(false);
	const [token, setToken] = useState<Token | null>(null);
	const [user, setUser] = useState(initialState.user);
	const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null);
	const {
		status: firebaseAuthStatus,
		signIn: firebaseSignIn,
		singOut: firebaseSignOut,
	} = useContext(FirebaseAuthContext);
	const authenticated = !!token;

	// Handle initial token
	useEffect(() => {
		const persistedToken = getPersistedToken();
		if (!persistedToken) {
			setAuthReady(true);
			return;
		}

		if (hasTokenExpired(persistedToken.refreshTokenExpireTimestamp, 30)) {
			signOut();
			setAuthReady(true);
			return;
		}

		if (hasTokenExpired(persistedToken.accessTokenExpireTimestamp, 300)) {
			r.post<TokenResponse>('/admin/auth/refresh', {
				refreshToken: persistedToken.refreshToken,
			})
				.then((response) => {
					updateToken(createTokenFromTokenResponse(response.data));
					setAuthReady(true);
				})
				.catch(() => {
					signOut();
					setAuthReady(true);
				});
			return;
		}

		updateToken(persistedToken);
		setAuthReady(true);
	}, []);

	useEffect(() => {
		if (!token) return;

		r.get<FirebaseUser>('/admin/users/firebase').then(({ data }) => setFirebaseUser(data));
	}, [token]);

	useEffect(() => {
		if (!firebaseUser) return;
		if (firebaseAuthStatus === FirebaseAuthStatus.Pending) return;
		if (firebaseAuthStatus === FirebaseAuthStatus.SignedIn) return;

		firebaseSignIn(firebaseUser.email, firebaseUser.password);
	}, [firebaseUser, firebaseAuthStatus]);

	useSetSentryUser(user);

	useTokenRefresher(token, {
		onTokenExpire() {
			signOut();
			setAuthReady(true);
		},
		onTokenChange(token: Token) {
			updateToken(token);
			setAuthReady(true);
		},
	});

	const updateToken = (token: Token | null) => {
		setToken(token);
		if (token) {
			setPersistedToken(token);
		} else {
			removePersistedToken();
		}
	};

	useEffect(() => {
		if (!authenticated) return;
		fetchMe();
	}, [authenticated]);

	const fetchMe = async () => {
		const response = await r.get<User>(`/admin/users/me`);

		setUser(response.data);
	};

	const signIn = async (email: string, password: string) => {
		const response = await r.post<TokenResponse>('/admin/auth/email', {
			email,
			password,
		});

		updateToken(createTokenFromTokenResponse(response.data));
	};

	const signOut = async () => {
		setUser(null);
		updateToken(null);
		firebaseSignOut();
	};

	const context = {
		// TODO: Dont need. just hot reload bug?
		authReady,
		authenticated,
		user,
		signOut,
		signIn,
	};

	return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export default AuthContext;
