import styles from './styles/NewsArticleScreen.module.scss';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Button, Input, Divider, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';
import StoreContext from '../state/Store';
import PageContainer from '../components/PageContainer';
import NewsArticleActions from './components/NewsArticleActions';
import NewsArticleContex from '../state/NewsArticle';
import NewsArticle from '../models/NewsArticle';
import dayjs from 'dayjs';
import StoreSelector from '../components/StoreSelector';
import useFetch from '../hooks/fetch';
import Separator from '../components/Separator';
import ListImage from '@components/table/ListImage';
import DateTime from '@helpers/date-time';

const { Search } = Input;

const columns: ColumnsType<NewsArticle> = [
	{
		title: 'Bild',
		key: 'imageUrl',
		width: 70,
		render: (newsArticle: NewsArticle) => <ListImage src={newsArticle.imageUrl} />,
	},
	{
		title: 'Titel',
		dataIndex: 'title',
		key: 'title',
		width: 160,
		ellipsis: true,
	},
	{
		title: 'Beskrivning',
		dataIndex: 'body',
		key: 'body',
		width: 160,
		ellipsis: true,
	},
	{
		title: 'Skapad',
		dataIndex: 'createdAt',
		key: 'createdAt',
		render: (createdAt) => DateTime.fromISOUTC(createdAt).toFormat('YYYY-MM-DD HH:mm'),
		sortDirections: ['descend', 'ascend'],
		width: 210,
		sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
	},
	{
		title: 'Uppdaterad',
		dataIndex: 'updatedAt',
		key: 'updatedAt',
		render: (updatedAt) => (updatedAt ? DateTime.fromISOUTC(updatedAt).toFormat('YYYY-MM-DD HH:mm') : null),
		sortDirections: ['descend', 'ascend'],
		width: 210,
		sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
	},
	{
		width: 150,
		responsive: ['sm'],
		fixed: 'right',
		render: (_, newsArticle: NewsArticle) => <NewsArticleActions id={newsArticle.id} />,
	},
	{
		responsive: ['xs'],
		width: 50,
		fixed: 'right',
		render: (_, newsArticle: NewsArticle) => <NewsArticleActions id={newsArticle.id} />,
	},
];

const NewsArticleScreen = () => {
	const { stores, defaultStore } = useContext(StoreContext);
	const { newsArticles, setNewsArticles } = useContext(NewsArticleContex);

	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const getStoreIdParam = (searchParams: URLSearchParams) => {
		const storeIdParam = searchParams.get('storeId');
		return storeIdParam && stores.find((s) => s.id === parseInt(storeIdParam)) !== undefined
			? parseInt(storeIdParam)
			: null;
	};
	const storeIdParam = getStoreIdParam(searchParams);

	const [searchTerm, setSearchterm] = useState('');
	const [selectedStore, setSelectedStore] = useState(storeIdParam || defaultStore);

	const { isFetching } = useFetch<NewsArticle[]>(`/news?storeId=${selectedStore}`, setNewsArticles, () => {
		notification.error({
			message: 'Vi kunde inte hämta nyheterna för butiken. Vänligen försök igen.',
			placement: 'bottomRight',
			duration: 10,
		});
	});

	useEffect(() => {
		if (selectedStore === -1) {
			setSearchParams('');
			return;
		}

		setSearchParams(`storeId=${selectedStore}`);
	}, [selectedStore, setSearchParams]);

	const displayedNewsArticles = useMemo(
		() =>
			newsArticles.filter((newsArticle) => {
				return newsArticle.title.toLowerCase().indexOf(searchTerm.toString().toLowerCase()) >= 0;
			}),
		[newsArticles, searchTerm]
	);

	return (
		<PageContainer
			title="Nyheter"
			extra={
				<Button onClick={() => navigate(`create/?storeId=${selectedStore}`)} type="primary" icon={<PlusOutlined />}>
					Skapa nyhet
				</Button>
			}
		>
			<div className={styles.actionBar}>
				<Search
					value={searchTerm}
					onChange={(e) => setSearchterm(e.target.value)}
					size="large"
					placeholder="Sök efter titel"
					style={{ width: 400 }}
				/>
				{stores.length > 1 && (
					<>
						<Separator />
						<StoreSelector stores={stores} selectedStore={selectedStore} onChange={setSelectedStore} />
					</>
				)}
			</div>
			<Divider />
			<Table
				loading={isFetching}
				rowKey={(article) => article.id}
				locale={{
					emptyText: 'Inga nyheter',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="middle"
				columns={columns}
				dataSource={displayedNewsArticles}
			/>
		</PageContainer>
	);
};

export default NewsArticleScreen;
