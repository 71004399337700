import React, { useMemo, useState } from 'react';
import NewsArticle from '../models/NewsArticle';

interface State {
	newsArticles: NewsArticle[];
	setNewsArticles: (newsArticles: NewsArticle[]) => void;
	addNewsArticle: (newsArticle: NewsArticle) => void;
	updateNewsArticle: (newsArticle: NewsArticle) => void;
	deleteNewsArticle: (id: number) => void;
}

const initialState: State = {
	newsArticles: [],
	setNewsArticles: () => {},
	addNewsArticle: () => {},
	updateNewsArticle: () => {},
	deleteNewsArticle: () => {},
};

const NewsArticleContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const NewsArticleContextProvider = ({ children }: Props) => {
	const [newsArticles, setNewsArticles] = useState<NewsArticle[]>([]);

	const value: State = useMemo(() => {
		return {
			newsArticles,
			setNewsArticles,
			addNewsArticle: (newsArticle: NewsArticle) => setNewsArticles(newsArticles.concat(newsArticle)),
			updateNewsArticle: (newsArticle: NewsArticle) =>
				setNewsArticles(newsArticles.map((a) => (a.id === newsArticle.id ? newsArticle : a))),
			deleteNewsArticle: (id: number) => setNewsArticles(newsArticles.filter((a) => a.id !== id)),
		};
	}, [newsArticles]);

	return <NewsArticleContext.Provider value={value}>{children}</NewsArticleContext.Provider>;
};

export default NewsArticleContext;
