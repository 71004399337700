import styles from './styles/EditUserScreen.module.scss';
import { Button, Card, Form, Space, notification, Input, Select, Divider } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMemo, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import r from '../../http';
import PageContainer from '@components/PageContainer';
import UserContext from '../../state/User';
import User from '@models/User';
import { getSwedishRoleName } from '@helpers/role';
import Role from '../../models/Role';
import Title from 'antd/es/typography/Title';
import StoreContext from '../../state/Store';

const EditUserScreen = () => {
	const { stores } = useContext(StoreContext);
	const { users, updateUser } = useContext(UserContext);
	const navigate = useNavigate();
	const { id } = useParams();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [form] = Form.useForm();

	const selectedRole = Form.useWatch('role', form);

	const selectedStores = Form.useWatch('stores', form) ?? [];
	const filteredStores = stores.filter(
		(store) => !selectedStores.find((selectedStore: any) => selectedStore.value === store.id)
	);

	const roleOptions = Object.keys(Role).map((key) => ({
		value: key,
		label: getSwedishRoleName(Role[key as keyof typeof Role]),
	}));

	const user = useMemo(() => users.find((user) => user.id === parseInt(id!)), [users, id]);

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	if (!user) {
		return <h1>Loading ...</h1>;
	}

	const submit = async (form: any) => {
		const body = {
			id: user.id,
			firstName: form.firstName,
			lastName: form.lastName,
			email: form.email,
			password: form.newPassword,
			roles: [form.role],
			franchiceStoreIds: selectedStores.map((s: any) => s.value),
		};

		try {
			setIsSubmitting(true);
			const response = await r.put<User>(`/admin/admin-users/${user.id}`, body);
			updateUser(response.data);

			notification.success({
				message: 'Användare uppdaterad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	const validatePasswordStrength = (rule: any, value: string) => {
		return new Promise<void>((resolve, reject) => {
			const isValid = /^(?=.*[A-Z])(?=.*\d).{6,}$/.test(value);
			if (!isValid) {
				if (value === '' || value === undefined) {
					resolve();
				} else {
					reject('Lösenordet måste vara minst 6 tecken långt och inkludera en versal och en siffra.');
				}
			} else {
				resolve();
			}
		});
	};

	return (
		<PageContainer back title="Uppdatera användare">
			<Form
				{...layout}
				labelAlign="left"
				form={form}
				size="large"
				onFinish={submit}
				initialValues={{
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
					role: user.roles[0],
					stores: user.franchiceStoreIds.map((id) => {
						return {
							label: `${stores.find((s) => s.id === id)?.name}`,
							value: id,
							key: `${id}`,
							title: `${stores.find((s) => s.id === id)?.name}`,
						};
					}),
				}}
			>
				<Card>
					<Form.Item label="Förnamn" name="firstName" rules={[{ required: true, message: 'Vänligen ange förnamn' }]}>
						<Input placeholder="Förnamn" autoComplete="new-firstName" spellCheck="false" />
					</Form.Item>
					<Divider />
					<Form.Item label="Efternamn" name="lastName" rules={[{ required: true, message: 'Vänligen ange efternamn' }]}>
						<Input placeholder="Efternamn" autoComplete="new-lastName" spellCheck="false" />
					</Form.Item>
					<Divider />
					<Form.Item
						label="Email"
						name="email"
						rules={[
							{ required: true, message: 'Vänligen ange email' },
							{ type: 'email', message: 'Ange en giltig email-adress' },
						]}
					>
						<Input placeholder="Email" autoComplete="new-email" spellCheck="false" />
					</Form.Item>
					<Divider />
					<Form.Item label="Roll" name="role" rules={[{ required: true, message: 'Vänligen välj en roll' }]}>
						<Select placeholder="Roll" options={roleOptions} />
					</Form.Item>
					{selectedRole === 'FranchiceAdmin' ? (
						<>
							<Divider />
							<Form.Item
								label="Butik"
								name="stores"
								rules={[{ required: true, message: 'Vänligen välj minst en butik' }]}
							>
								<Select
									filterOption={(input, option) =>
										(option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
									}
									mode="multiple"
									placeholder="Butiker"
									allowClear
									labelInValue
									style={{ width: '100%' }}
								>
									{filteredStores.map((store) => (
										<Select.Option key={store.id} title={store.name} value={store.id}>
											{store.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</>
					) : (
						<></>
					)}
					<Divider />
					<Title level={5}>Ändra lösenord</Title>
					<Form.Item
						label="Nytt lösenord"
						name="newPassword"
						extra="Notera: Fyll endast i detta fält ifall du vill byta användarens lösenord."
						rules={[{ validator: validatePasswordStrength }]}
					>
						<Input.Password placeholder="Nytt lösenord" autoComplete="new-password" spellCheck="false" />
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<EditOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Uppdatera användare
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default EditUserScreen;
