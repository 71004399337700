import styles from './styles/CreateProductScreen.module.scss';
import { Button, Card, Divider, Form, Input, Space, Upload, InputNumber, notification } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Product from '../models/Product';
import ProductContext from '../state/Product';
import r from '../http';
import PageContainer from '../components/PageContainer';

const AddProductScreen = () => {
	const { addProduct } = useContext(ProductContext);
	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [form] = Form.useForm();

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	const submit = async (form: any) => {
		const body = new FormData();
		body.append('name', form.name);
		body.append('image', form.image.file);
		body.append('articleNumber', form.articleNumber);
		body.append('ean', form.ean);
		body.append('inPrice', form.inPrice);
		try {
			setIsSubmitting(true);
			const response = await r.post<Product>('/admin/products', body);
			addProduct(response.data);
			notification.success({
				message: 'Produkt skapad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	return (
		<PageContainer back title="Skapa produkt">
			<Form {...layout} labelAlign="left" form={form} size="large" onFinish={submit}>
				<Card>
					<Form.Item label="Namn" name="name" rules={[{ required: true, message: 'Vänligen ange namn' }]}>
						<Input placeholder="Namn" />
					</Form.Item>
					<Divider />
					<Form.Item label="Bild" name="image" rules={[{ required: true, message: 'Vänligen välj en bild' }]}>
						<Upload
							accept="image/*"
							listType="picture"
							multiple={false}
							beforeUpload={() => false}
							onRemove={() => {
								requestAnimationFrame(() => {
									form.resetFields(['image']);
									form.validateFields(['image']);
								});
								return true;
							}}
							previewFile={async (file) => URL.createObjectURL(file)}
							maxCount={1}
						>
							<Button icon={<UploadOutlined />}>Upload</Button>
						</Upload>
					</Form.Item>
					<Divider />
					<Form.Item
						label="Artikelnummer"
						name="articleNumber"
						rules={[{ required: true, message: 'Vänligen ange artikelnummer' }]}
					>
						<Input type="number" placeholder="Artikelnummer" />
					</Form.Item>
					<Divider />
					<Form.Item
						label="EAN"
						name="ean"
						rules={[
							{ min: 8, max: 13, message: 'Vänligen ange giltigt EAN' },
							{ required: true, message: 'Vänligen ange EAN' },
						]}
					>
						<Input type="number" placeholder="EAN" />
					</Form.Item>
					<Divider />
					<Form.Item
						label="Inköpspris"
						name="inPrice"
						rules={[{ required: true, message: 'Vänligen ange inköpspris' }]}
					>
						<InputNumber placeholder="Inköpspris" addonBefore="SEK" />
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<PlusOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Skapa produkt
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default AddProductScreen;
