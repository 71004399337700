import React, {  useMemo, useState } from 'react';
import { BannedUser } from '@models/BannedUser';
import useFetch from '@hooks/fetch';

interface State {
	bannedUsers: BannedUser[];
	addBannedUser: (user: BannedUser) => void;
	updateBannedUser: (user: BannedUser) => void;
	deleteBannedUser: (userIdentifier: string) => void;
}

const initialState: State = {
	bannedUsers: [],
	addBannedUser: () => undefined,
	updateBannedUser: () => undefined,
	deleteBannedUser: () => undefined,
};

const BannedUserContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const BannedUserContextProvider = ({ children }: Props) => {
	const [bannedUsers, setBannedUsers] = useState<BannedUser[]>([]);

	useFetch<BannedUser[]>('/admin/banned-users', (data) => setBannedUsers(data));

	const value: State = useMemo(() => {
		return {
			bannedUsers,
			addBannedUser: (user: BannedUser) => setBannedUsers(bannedUsers.concat(user)),
			updateBannedUser: (user: BannedUser) => setBannedUsers(bannedUsers.map((u) => (u.id === user.id ? user : u))),
			deleteBannedUser: (userIdentifier: string) => setBannedUsers(bannedUsers.filter((u) => u.userIdentifier !== userIdentifier)),
		};
	}, [bannedUsers]);

	return <BannedUserContext.Provider value={value}>{children}</BannedUserContext.Provider>;
};

export default BannedUserContext;
