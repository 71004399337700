import styles from '../styles/TobaccoProducts.module.scss';
import { Divider, Input, Table, Typography, Button, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';
import { useContext, useMemo, useState } from 'react';
import VendingMachineProductActions from './VendingMachineProductActions';
import useFetch from '../../../hooks/fetch';
import ListImage from '@components/table/ListImage';
import ProductCategory from '@models/ProductCategory';
import TobaccoProductContext from '../../../state/TobaccoProduct';
import useProductCategories from '@hooks/product-category';
import { VendingMachineProduct, VendingMachineProductResponse } from '@models/VendingMachineProducts';
import VendingMachineContext from '../../../state/VendingMachine';
import AddVendingMachineProductModal from './AddVendingMachineProductModal';
import EditVendingMachineProductModal from './EditVendingMachineProductModal';

const { Search } = Input;
const { Text } = Typography;

const getColumns = (
	productCategoryMap: Map<number, ProductCategory>,
	removeVendingMachineProduct: (productId: number) => void,
	onEdit: (vendingMachineProduct: VendingMachineProduct) => void
): ColumnsType<VendingMachineProduct> => [
	{
		title: 'Position',
		key: 'position',
		defaultSortOrder: 'ascend',
		sorter: (a, b) => a.position - b.position,
		width: 100,
		render: (_, vendingMachineProduct) => <Text strong>{vendingMachineProduct.position}</Text>,
	},
	{
		title: 'Bild',
		dataIndex: 'imageUrl',
		key: 'imageUrl',
		render: (_, vendingMachineProduct) => <ListImage src={vendingMachineProduct.tobaccoProduct.imageUrl} />,
		width: 70,
	},
	{
		title: 'Namn',
		render: (_, vendingMachineProduct) => <Text>{vendingMachineProduct.tobaccoProduct.name}</Text>,
		key: 'name',
		width: 160,
		sorter: (a, b) => a.tobaccoProduct.name.localeCompare(b.tobaccoProduct.name),
	},
	{
		title: 'Kategori',
		render: (_, vendingMachineProduct) => (
			<Text>{productCategoryMap?.get(vendingMachineProduct.tobaccoProduct.categoryId)?.name}</Text>
		),
		width: 160,
		sorter: (a, b) => {
			const categoryA = productCategoryMap?.get(a.tobaccoProduct.categoryId)?.name || '';
			const categoryB = productCategoryMap?.get(b.tobaccoProduct.categoryId)?.name || '';
			return categoryA.localeCompare(categoryB);
		},
	},
	{
		title: 'Pris',
		sortDirections: ['descend', 'ascend'],
		width: 160,
		sorter: (a, b) => a.price - b.price,
		render: (_, vendingMachineProduct) => (
			<div className={styles.tablePrice}>SEK {vendingMachineProduct.price.toFixed(2)}</div>
		),
	},
	{
		width: 150,
		responsive: ['sm'],
		fixed: 'right',
		render: (_, vendingMachineProduct: VendingMachineProduct) => (
			<VendingMachineProductActions
				product={vendingMachineProduct}
				removeProduct={() => removeVendingMachineProduct(vendingMachineProduct.id)}
				onEdit={onEdit}
			/>
		),
	},
	{
		responsive: ['xs'],
		width: 50,
		fixed: 'right',
		render: (_, vendingMachineProduct: VendingMachineProduct) => (
			<VendingMachineProductActions
				product={vendingMachineProduct}
				removeProduct={() => removeVendingMachineProduct(vendingMachineProduct.id)}
				onEdit={onEdit}
			/>
		),
	},
];

interface Props {
	vendingMachineId: number;
}

const VendingMachineProductsView = ({ vendingMachineId }: Props) => {
	const { vendingMachines } = useContext(VendingMachineContext);
	const { tobaccoProducts } = useContext(TobaccoProductContext);
	const [vendingMachineProducts, setVendingMachineProducts] = useState<VendingMachineProduct[]>([]);
	const [searchTerm, setSearchterm] = useState('');
	const [showAddProductsModal, setShowAddProductsModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState<VendingMachineProduct | undefined>(undefined);

	const { productCategories } = useProductCategories();

	const { isFetching } = useFetch<VendingMachineProductResponse[]>(`/admin/vending-machine-products`, (data) => {
		const vendingMachineProducts = data
			.map((vp) => {
				if (vendingMachineId === vp.vendingMachineId)
					return {
						id: vp.id,
						position: vp.position,
						price: vp.price,
						tobaccoProduct: tobaccoProducts.find((tp) => tp.id === vp.tobaccoProductId),
						vendingMachine: vendingMachines.find((vm) => vm.id === vp.vendingMachineId),
					} as VendingMachineProduct;
			})
			.filter((vmp) => vmp !== undefined) as VendingMachineProduct[];
		setVendingMachineProducts(vendingMachineProducts);
	});

	const addVendingMachineProducts = (product: VendingMachineProductResponse) => {
		if (!vendingMachineProducts) return;
		const newProduct: VendingMachineProduct = {
			id: product.id,
			position: product.position,
			price: product.price,
			tobaccoProduct: tobaccoProducts.find((tp) => tp.id === product.tobaccoProductId),
			vendingMachine: vendingMachines.find((vm) => vm.id === product.vendingMachineId),
		} as VendingMachineProduct;
		setVendingMachineProducts([...vendingMachineProducts, newProduct]);
	};

	const editVendingMachineProduct = (product: VendingMachineProductResponse) => {
		if (!vendingMachineProducts) return;
		const updatedProduct: VendingMachineProduct = {
			id: product.id,
			position: product.position,
			price: product.price,
			tobaccoProduct: tobaccoProducts.find((tp) => tp.id === product.tobaccoProductId),
			vendingMachine: vendingMachines.find((vm) => vm.id === product.vendingMachineId),
		} as VendingMachineProduct;
		setVendingMachineProducts([...vendingMachineProducts.filter((vpm) => vpm.id !== product.id), updatedProduct]);
	};

	const removeVendingMachineProduct = (vendingMachineProductId: number) => {
		setVendingMachineProducts(vendingMachineProducts.filter((vmp) => vmp.id !== vendingMachineProductId));
	};

	const productCategoryMap = useMemo(
		() => new Map(productCategories.map((productCategory) => [productCategory.id, productCategory])),
		[]
	);

	const filterProperties: ['name'] = ['name'];
	const displayedVendingMachineProducts = vendingMachineProducts.filter((vendingMachineProduct) =>
		filterProperties.some((filterProperty) =>
			vendingMachineProduct.tobaccoProduct[filterProperty].toLowerCase().includes(searchTerm.toLowerCase())
		)
	);

	const onEdit = (vendingMachineProduct: VendingMachineProduct) => {
		setShowEditModal(true);
		setSelectedProduct(vendingMachineProduct);
		return;
	};

	return (
		<div className={styles.storeProducts}>
			<Typography.Title level={2}>Tobaksprodukter</Typography.Title>
			<Divider />
			<div className={styles.actionBar}>
				<Search
					value={searchTerm}
					onChange={(e) => setSearchterm(e.target.value)}
					size="large"
					placeholder="Sök efter namn"
					style={{ width: 400 }}
				/>
				<Button size="large" onClick={() => setShowAddProductsModal(true)} type="primary" icon={<PlusOutlined />}>
					Lägg till tobaksprodukter
				</Button>
			</div>
			<Divider />
			<Table
				locale={{
					emptyText: 'Inga tobaksprodukter',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				loading={isFetching}
				columns={getColumns(productCategoryMap, removeVendingMachineProduct, onEdit)}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				dataSource={displayedVendingMachineProducts}
				size="middle"
				scroll={{ x: '100%' }}
				rowKey={(product) => product.id}
			/>
			<AddVendingMachineProductModal
				open={showAddProductsModal}
				onClose={() => setShowAddProductsModal(false)}
				addProduct={addVendingMachineProducts}
				vendingMachineId={vendingMachineId}
				existingProducts={vendingMachineProducts}
			/>
			<EditVendingMachineProductModal
				open={showEditModal}
				onClose={() => setShowEditModal(false)}
				editProduct={editVendingMachineProduct}
				vendingMachineId={vendingMachineId}
				existingProducts={vendingMachineProducts}
				vendingMachineProduct={selectedProduct}
			/>
		</div>
	);
};

export default VendingMachineProductsView;
