import { Checkbox, Divider, Image, Modal, Space, Typography } from 'antd';
import html2canvas from 'html2canvas';
import dayjs from 'dayjs';
import printGuide from '../assets/print-guide.png';
import PrintHelp from '../components/PrintHelp';

export const previewUpdateDelay = 300;

export const printPdf = (iFrameId: string) => {
	const shouldShowPrintHelpDialog = localStorage.getItem('@offer-print:hide-print-dialog') !== 'true';

	if (shouldShowPrintHelpDialog) {
		showPrintHelpDialog(iFrameId);
		return;
	}

	doPrint(iFrameId);
};

function doPrint(iFrameId: string) {
	const iframe = document.getElementById(iFrameId) as HTMLIFrameElement | null;
	if (iframe) {
		const iframeWindow = iframe.contentWindow;
		if (iframeWindow) {
			setTimeout(() => {
				iframeWindow.print();
			}, previewUpdateDelay);
		}
	}
}

export const printJpg = async (elementId: string) => {
	const element = document.getElementById(elementId);

	if (element) {
		html2canvas(element, {
			scale: 10,
			useCORS: true,
			allowTaint: true,
		}).then((canvas) => {
			const imageDataUrl = canvas.toDataURL('image/jpg');

			const downloadLink = document.createElement('a');
			downloadLink.href = imageDataUrl;
			downloadLink.download = `24food_instagram_${dayjs().format('YYYYMMDDHHmmss').toString()}.jpg`;
			downloadLink.click();
		});
	}
};

function showPrintHelpDialog(iFrameId: string) {
	Modal.info({
		title: 'Inställningar för utskrigt av reklamblad',
		cancelText: 'Avbryt',
		okCancel: true,
		okText: 'Skriv ut',
		width: 700,
		onOk() {
			localStorage.setItem(
				'@offer-print:hide-print-dialog',
				(document.getElementById('hide-print-dialog') as HTMLInputElement).checked.toString()
			);
			doPrint(iFrameId);
		},
		content: (
			<div>
				<PrintHelp />
				<Divider />
				<Checkbox id="hide-print-dialog">Visa inte det här meddelandet igen</Checkbox>
			</div>
		),
	});
}
