import { Button, Card, Divider, Form, Input, Space, Select, InputNumber, notification, Switch, DatePicker } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import StoreContext from '../state/Store';
import styles from './styles/CreateOfferScreen.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import r from '../http';
import Offer, { OfferType, offerTypeTranslations } from '../models/Offer';
import OfferContext from '../state/Offer';
import PageContainer from '../components/PageContainer';
import FormImage from '../components/form/Image';
import dayjs from 'dayjs';

const EditOfferScreen = () => {
	const { offers, updateOffer } = useContext(OfferContext);
	const { stores } = useContext(StoreContext);

	const [offer, setOffer] = useState<Offer>();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const navigate = useNavigate();
	const id = useParams().id!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
	const [form] = Form.useForm();

	const selectedOfferType = Form.useWatch('offerType', form);

	useEffect(() => {
		(async () => {
			const savedOffer = offers.find((offer) => offer.id === parseInt(id));
			if (savedOffer) {
				setOffer(savedOffer);
				return;
			}

			const { data } = await r.get<Offer>(`/admin/offers/${id}`);
			setOffer(data);
		})();
	}, [id, offers]);

	function selectAllStores() {
		form.setFieldValue(
			'stores',
			stores.map((store) => store.id)
		);
	}

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	if (!offer) {
		return <h1>Loading ...</h1>;
	}

	const submit = async (form: any) => {
		const body = new FormData();
		body.append('id', offer.id.toString());
		body.append('title', form.title);
		if (form.image[0].originFileObj) {
			body.append('image', form.image[0].originFileObj);
		}
		body.append('showOnStoreOpen', form.showOnStoreOpen);
		form.stores.forEach((store: any) => {
			body.append('storeIds[]', store);
		});
		if (form.expirationDate) {
			body.append('expirationDate', form.expirationDate);
		}

		if (selectedOfferType === OfferType.DiscountedPrice) {
			body.append('price', form.price);
		} else if (selectedOfferType === OfferType.QuantityForPrice) {
			body.append('price', form.price);
			body.append('quantity', form.quantity);
		} else if (selectedOfferType === OfferType.QuantityForQuantity) {
			body.append('quantity', form.quantity);
			body.append('quantityOffered', form.quantityOffered);
		}

		body.append('type', selectedOfferType);

		try {
			setIsSubmitting(true);
			const response = await r.put<Offer>(`/admin/offers/${offer.id}`, body);
			updateOffer(response.data);

			notification.success({
				message: 'Erbjudande uppdaterat!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	return (
		<PageContainer back title="Uppdatera erbjudande" subTitle="">
			<Form
				{...layout}
				labelAlign="left"
				form={form}
				size="large"
				onFinish={submit}
				initialValues={{
					stores: offer.storeIds,
					title: offer.title,
					showOnStoreOpen: offer.showOnStoreOpen,
					image: offer.imageUrl
						? [
								{
									uid: '-1',
									name: offer.imageUrl.split('/').reverse()[0],
									status: 'done',
									url: offer.imageUrl,
								},
						  ]
						: [],
					expirationDate: offer.expirationDate ? dayjs(offer.expirationDate) : null,
					offerType: offer.type,
					price:
						offer.type === OfferType.DiscountedPrice || offer.type === OfferType.QuantityForPrice ? offer.price : null,
					quantity:
						offer.type === OfferType.QuantityForPrice || offer.type === OfferType.QuantityForQuantity
							? offer.quantity
							: null,
					quantityOffered: offer.type === OfferType.QuantityForQuantity ? offer.quantityOffered : null,
				}}
			>
				<Card>
					<Form.Item
						extra={
							<Button style={{ marginTop: 10 }} onClick={selectAllStores}>
								Välj alla butiker
							</Button>
						}
						label="Butik"
						name="stores"
						rules={[{ required: true, message: 'Vänligen välj minst en butik' }]}
					>
						<Select
							mode="multiple"
							placeholder="Butiker"
							allowClear
							style={{ width: '100%' }}
							optionFilterProp="label"
							options={stores.map((store) => ({
								value: store.id,
								label: store.name,
							}))}
						/>
					</Form.Item>
					<Divider />
					<Form.Item label="Titel" name="title" rules={[{ required: true, message: 'Vänligen ange titel' }]}>
						<Input placeholder="Titel" />
					</Form.Item>
					<Divider />
					<FormImage label="Bild" name="image" removeable={false} />
					<Divider />
					<Form.Item label="Pris" required>
						<div className={styles.offerTypeContainer}>
							<Form.Item name="offerType" noStyle>
								<Select
									style={{ width: 240 }}
									options={[
										{ value: OfferType.DiscountedPrice, label: offerTypeTranslations[OfferType.DiscountedPrice] },
										{
											value: OfferType.QuantityForPrice,
											label: offerTypeTranslations[OfferType.QuantityForPrice],
										},
										{
											value: OfferType.QuantityForQuantity,
											label: offerTypeTranslations[OfferType.QuantityForQuantity],
										},
									]}
								/>
							</Form.Item>
							<Form.Item
								name="quantity"
								noStyle
								rules={[
									{
										required: selectedOfferType !== OfferType.DiscountedPrice,
										message: 'Vänligen ange antal',
									},
								]}
								hidden={selectedOfferType === OfferType.DiscountedPrice}
							>
								<InputNumber placeholder="Antal" addonAfter="st" min={0} max={9999} />
							</Form.Item>
							<Form.Item
								name="price"
								noStyle
								rules={[
									{
										required: selectedOfferType !== OfferType.QuantityForQuantity,
										message: 'Vänligen ange priset',
									},
								]}
								hidden={selectedOfferType === OfferType.QuantityForQuantity}
							>
								<InputNumber placeholder="Pris" addonAfter="SEK" min={0} max={9999} precision={2} />
							</Form.Item>
							<Form.Item
								name="quantityOffered"
								noStyle
								rules={[
									{
										required: selectedOfferType === OfferType.QuantityForQuantity,
										message: 'Vänligen ange antal',
									},
								]}
								hidden={selectedOfferType !== OfferType.QuantityForQuantity}
							>
								<InputNumber placeholder="Antal" addonAfter="st" min={0} max={9999} />
							</Form.Item>
						</div>
					</Form.Item>

					<Divider />
					<Form.Item label="Visa när man öppnar butik" name="showOnStoreOpen" valuePropName="checked">
						<Switch />
					</Form.Item>
					<Divider />
					<Form.Item
						label="Giltigt till och med"
						name="expirationDate"
						help="Notera: Genom att lämna detta fält tomt kommer erbjudandet att gälla tills vidare"
					>
						<DatePicker
							placeholder="Välj datum"
							format={'YYYY-MM-DD'}
							disabledDate={(date) => date.set('hour', 23) < dayjs().set('hour', 1)}
						/>
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<EditOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Uppdatera erbjudande
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default EditOfferScreen;
