import { useState } from 'react';
import PageContainer from '../components/PageContainer';
import AccountApplicationsView from './account-applications/AccountApplicationsView';
import PurchasesView from './purchases/PurchasesView';
import VendingMachineApplicationsView from './vending-machine-applications/VendingMachineApplicationsView';
import { Button } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import { initSound } from '../functions/initSound';

const ApplicationsScreen = () => {
	const [isVisible, setIsVisible] = useState<boolean>(false);

	const onVisible = () => {
		initSound();
		setIsVisible(true);
	};

	return (
		<PageContainer title="Ansökningar">
			{isVisible ? (
				<>
					<AccountApplicationsView />
					<VendingMachineApplicationsView />
					<PurchasesView />
				</>
			) : (
				<div style={{ margin: 'auto' }}>
					<Button icon={<PlusSquareOutlined />} size="large" type="primary" onClick={onVisible}>
						Visa ansökningar
					</Button>
				</div>
			)}
		</PageContainer>
	);
};

export default ApplicationsScreen;
