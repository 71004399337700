import { Table, Divider, Input, Select } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import styles from './styles/VendingMachineEvents.module.scss';
import PageContainer from '../components/PageContainer';
import StoreContext from '../state/Store';
import r from '../http';
import Separator from '../components/Separator';
import StatusColumn from './components/StatusColumn';
import DispatchResultColumn from './components/DispatchResultColumn';
import Event, { PurchaseStatus } from './models/event';
import PurchasedProducts from './components/PurchasedProducts';
import ListImage from '@components/table/ListImage';
import VendingMachineContext from '../state/VendingMachine';
import { VendingMachine } from '@models/VendingMachine';
import DateTime from '@helpers/date-time';

const { Search } = Input;

const getColumns = (vendingMachineMap: Map<number, VendingMachine>): ColumnsType<Event> => [
  {
    title: 'Tid',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: string) => DateTime.fromISOUTC(createdAt).toFormat('YYYY-MM-DD HH:mm:ss'),
    width: 110,
  },
  {
    title: 'Identitetshandling',
    dataIndex: 'identificationImageUrl',
    key: 'identificationImageUrl',
    render: (imageUrl: string) => <ListImage src={imageUrl} />,
    width: 170,
  },
  {
    title: 'Personnummer',
    dataIndex: 'personalIdentificationNumber',
    key: 'personalIdentificationNumber',
    width: 110,
  },
  {
    title: 'Skåp',
    dataIndex: 'vendingMachineId',
    key: 'vendingMachineId',
    width: 170,
    render: (vendingMachineId: number) =>
      vendingMachineMap.get(vendingMachineId)?.name || <i>Kunde inte hitta skåpet</i>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    render: (status: PurchaseStatus) => <StatusColumn status={status} />,
  },
  {
    title: 'Utmatning',
    dataIndex: 'productShipmentRequestFailed',
    key: 'productShipmentRequestFailed',
    width: 100,
    render: (productShipmentRequestFailed, event) => (
      <DispatchResultColumn success={!productShipmentRequestFailed} status={event.status} />
    ),
  },
];

const PAGE_SIZE = 50;

const VendingMachineEvents = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let searchTermParam = searchParams.get('searchTerm') || '';
  let currentPage = parseInt(searchParams.get('pageNumber') || '1');
  const vendingMachineIdSearchParam = searchParams.get('vendingMachineId');
  let vendingMachineId: number | null = vendingMachineIdSearchParam ? parseInt(vendingMachineIdSearchParam) : null;

  const [events, setEvents] = useState<Event[]>([]);
  const [searchTerm, setSearchTerm] = useState(searchTermParam);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState<number>();

  const { stores } = useContext(StoreContext);
  const { vendingMachines } = useContext(VendingMachineContext);

  const vendingMachineMap = useMemo(
    () => new Map(vendingMachines.map((vendingMachine) => [vendingMachine.id, vendingMachine])),
    [vendingMachines]
  );

  const updateSearchParams = (newSearchParams: Record<string, string | null>) => {
    const previousParams = [...searchParams].reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {}
    );

    const combinedParams = {
      ...previousParams,
      ...newSearchParams,
    };

    const filteredParams = Object.entries(combinedParams).reduce((acc, [key, value]) => {
      if (value === null) return acc;

      return {
        ...acc,
        [key]: value,
      };
    }, {});

    setSearchParams(filteredParams);
  };

  const search = async () => {
    setIsLoading(true);
    try {
      let url = `/admin/vending-machine-events?pageSize=${PAGE_SIZE}&pageNumber=${currentPage}`;
      if (vendingMachineId) {
        url += `&vendingMachineId=${vendingMachineId}`;
      }
      if (searchTerm) {
        url += `&searchTerm=${searchTerm}`;
      }
      const response = await r.get<Event[]>(url);
      let totalItemsHeader = response.headers.get('pagination-total-count');
      if (totalItemsHeader) {
        setTotalItems(parseInt(totalItemsHeader));
      }
      setEvents(response.data);
    } catch (e) {}
    setIsLoading(false);
  };

  useEffect(() => {
    search();
  }, [vendingMachineId, currentPage]);

  return (
    <PageContainer title="Tobaksköp">
      <div className={styles.actionBar}>
        <Search
          value={searchTerm}
          onChange={({ target }) => setSearchTerm(target.value)}
          size="large"
          placeholder="Sök efter personnummer"
          style={{ width: 400 }}
          loading={isLoading}
          onSearch={(value) => {
            search();
            updateSearchParams({
              searchTerm: value,
              pageNumber: null,
            });
          }}
        />
        {stores.length > 1 && (
          <>
            <Separator />
            <Select
              className={styles.storeSelector}
              value={vendingMachineId ? vendingMachineId : -1}
              onChange={(vendingMachineId) => {
                updateSearchParams({
                  vendingMachineId: vendingMachineId === -1 ? null : vendingMachineId.toString(),
                  pageNumber: null,
                });
              }}
              placeholder="Välj butik"
              size="large"
              style={{ width: 200 }}
            >
              <Select.Option value={-1}>Alla skåp</Select.Option>
              {vendingMachines.map((vendingMachine) => (
                <Select.Option key={vendingMachine.id} value={vendingMachine.id}>
                  {vendingMachine.name || (
                    <span>
                      ID: {vendingMachine.id} <i>(Namn saknas)</i>
                    </span>
                  )}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
      </div>
      <Divider />
      <Table
        loading={isLoading}
        rowKey={(event) => event.createdAt}
        locale={{
          emptyText: 'Inga händelser',
          triggerDesc: 'Tryck för att sortera i fallande ordning',
          triggerAsc: 'Tryck för att sortera i stigande ordning',
          cancelSort: 'Tryck för att nollställa sortering',
        }}
        pagination={{
          showSizeChanger: false,
          position: ['bottomRight'],
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          total: totalItems,
          defaultCurrent: currentPage,
          current: currentPage,
          onChange: (pageNumber) => {
            updateSearchParams({
              pageNumber: pageNumber.toString(),
            });
          },
        }}
        scroll={{ x: '100%' }}
        expandable={{
          expandedRowRender: (event) => <PurchasedProducts products={event.products} />,
        }}
        size="middle"
        columns={getColumns(vendingMachineMap)}
        dataSource={events}
      />
    </PageContainer>
  );
};

export default VendingMachineEvents;
