import { Button, Form, Input, Card, Space, Divider } from 'antd';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import styles from './styles/CreateBannedAccountScreen.module.scss';
import { BannedUser } from '@models/BannedUser';
import TextArea from 'antd/lib/input/TextArea';
import { PlusOutlined } from '@ant-design/icons';
import useBannedUsers from '@hooks/banned-users';
import AuthContext from '../../state/Auth';
import Role from '../../models/Role';

const CreateBannedAccountScreen = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const navigate = useNavigate();
	const { addBannedUser } = useBannedUsers();
	const { user } = useContext(AuthContext);

	const onSubmit = async (formData: BannedUser) => {
		setIsSubmitting(true);
		addBannedUser({ userIdentifier: formData.userIdentifier, reason: formData.reason }).then((result) => {
			if (result === true) navigate(-1);
		});
		setIsSubmitting(false);
	};

	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 14 },
	};

	// If the user is a franchiseAdmin, hide the back button.
	// FranchiseAdmins can only add new banned users and nothing else.
	const showBackButton = user?.roles.includes(Role.Admin);

	return (
		<PageContainer title="Spärra konto" back={showBackButton}>
			<Form labelAlign="left" {...layout} onFinish={onSubmit} size="large">
				<Card className={styles.inputs}>
					<Form.Item
						label="Personnummer/Telefonnummer"
						name="userIdentifier"
						extra="Telefonnummer måste vara med riktnummer och utan mellanslag. Kopiera telefonnummret som det är från events."
						rules={[{ required: true, message: 'Vänligen ange personnumer eller telefonnummer.' }]}
					>
						<Input />
					</Form.Item>
					<Divider />
					<Form.Item label="Anledning" name="reason">
						<TextArea size="large" style={{ height: 120 }} />
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button loading={isSubmitting} type="primary" htmlType="submit" icon={<PlusOutlined />}>
								Spärra konto
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default CreateBannedAccountScreen;
