import styles from './styles/CreateStoreScreen.module.scss';
import { Alert, Button, Card, Divider, Form, Input, Radio, Space, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StoreContext from '../../state/Store';
import PageContainer from '../../components/PageContainer';
import FormImage from '../../components/form/Image';
import TTLockLockIdHelp from '../components/TTLockLockIdHelp';

const CreateStoreScreen = () => {
  const { createStore } = useContext(StoreContext);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };

  const submit = async (form: any) => {
    const store = {
      name: form.name,
      image: form.image[0].originFileObj,
      latitude: form.latitude,
      longitude: form.longitude,
      lockId: parseInt(form.lockId),
      city: form.city,
      address: form.address,
      postalCode: form.postalCode,
      information: form.information || '',
      email: form.email || '',
      phoneNumber: form.phoneNumber || '',
    };
    try {
      setIsSubmitting(true);
      await createStore(store);
      notification.success({
        message: 'Butik uppdaterad!',
        placement: 'bottomRight',
        duration: 5,
      });
      navigate(-1);
    } catch (e) {
      notification.error({
        message: 'Något gick fel',
        placement: 'bottomRight',
        duration: 5,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <PageContainer back title="Uppdatera butik">
      <Form {...layout} labelAlign="left" form={form} size="large" onFinish={submit}>
        <Card>
          <Form.Item
            help={
              <Alert
                style={{ marginTop: 10, marginBottom: 10 }}
                message="OBS"
                description="Namnet går ej att ändra efter att butiken är skapad. Dubbelkolla så att namnet är rätt."
                type="warning"
                showIcon
              />
            }
            label="Namn"
            name="name"
            rules={[{ required: true, message: 'Vänligen ange namn' }]}
          >
            <Input placeholder="Namn" />
          </Form.Item>
          <Divider />
          <FormImage
            label="Bild"
            name="image"
            rules={[{ required: true, message: 'Vänligen välj en bild' }]}
            removeable={false}
          />
          <Divider />
          <Form.Item label="Låstyp" rules={[{ required: true, message: 'Vänligen välj låstyp' }]}>
            <Radio.Group defaultValue="sciener" optionType="button" buttonStyle="solid" size="middle">
              <Radio value="sciener">TTLock</Radio>
            </Radio.Group>
          </Form.Item>
          <Divider />
          <Form.Item
            help={<TTLockLockIdHelp />}
            label="Lås ID"
            name="lockId"
            rules={[{ required: true, message: 'Vänligen ange Lås ID' }]}
          >
            <Input placeholder="Lås ID" />
          </Form.Item>
          <Divider />
          <Form.Item
            label="Position latitude"
            name="latitude"
            rules={[{ required: true, message: 'Vänligen ange latitude' }]}
          >
            <Input defaultValue="" placeholder="Position latitude" />
          </Form.Item>
          <Divider />
          <Form.Item
            label="Position longitude"
            name="longitude"
            rules={[{ required: true, message: 'Vänligen ange longitude' }]}
          >
            <Input defaultValue="" placeholder="Position longitude" />
          </Form.Item>
          <Divider />
          <Form.Item label="Stad" name="city" rules={[{ required: true, message: 'Vänligen ange stad' }]}>
            <Input placeholder="Stad" />
          </Form.Item>
          <Divider />
          <Form.Item label="Adress" name="address" rules={[{ required: true, message: 'Vänligen ange adress' }]}>
            <Input placeholder="Adress" />
          </Form.Item>
          <Divider />
          <Form.Item
            label="Postnummer"
            name="postalCode"
            rules={[
              {
                required: true,
                message: 'Vänligen ange postnummer',
              },
            ]}
          >
            <Input placeholder="Postnummer" />
          </Form.Item>
          <Divider />
          <Form.Item label="Information" name="information" rules={[{ required: false }]}>
            <Input placeholder="Information" />
          </Form.Item>
          <Divider />
          <Form.Item label="E-post" name="email">
            <Input placeholder="E-post" />
          </Form.Item>
          <Divider />
          <Form.Item label="Telefonnummer" name="phoneNumber">
            <Input placeholder="Telefonnummer" />
          </Form.Item>
        </Card>
        <div className={styles.actions}>
          <Form.Item>
            <Space size="middle">
              <Button onClick={() => navigate(-1)} type="text">
                Avbryt
              </Button>
              <Button icon={<EditOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
                Skapa butik
              </Button>
            </Space>
          </Form.Item>
        </div>
      </Form>
    </PageContainer>
  );
};

export default CreateStoreScreen;
