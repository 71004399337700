import styles from './styles/TobaccoProductsScreen.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Button, Input, Divider, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PageContainer from '../../components/PageContainer';
import useTobaccoProducts from '@hooks/tobacco-products';
import { useMemo, useState } from 'react';
import useProductCategories from '@hooks/product-category';
import ProductCategory from '@models/ProductCategory';
import { ColumnsType } from 'antd/es/table';
import TobaccoProduct from '@models/TobaccoProduct';
import ListImage from '@components/table/ListImage';
import Actions from './components/Actions';

const { Search } = Input;
const { Text } = Typography;

const getColumns = (productCategoryMap: Map<number, ProductCategory>): ColumnsType<TobaccoProduct> => [
	{
		title: 'ID',
		dataIndex: 'id',
		key: 'id',
		render: (id: number) => (
			<Link to={id.toString()}>
				<b>{id}</b>
			</Link>
		),
		width: 70,
		sorter: (a, b) => a.id - b.id,
	},
	{
		title: 'Bild',
		dataIndex: 'imageUrl',
		key: 'imageUrl',
		render: (imageUrl) => <ListImage src={imageUrl} />,
		width: 70,
	},
	{
		title: 'Namn',
		dataIndex: 'name',
		key: 'name',
		width: 160,
		sorter: (a, b) => a.name.localeCompare(b.name),
	},
	{
		title: 'Kategori',
		render: (_, tobaccoProduct) => <Text>{productCategoryMap?.get(tobaccoProduct.categoryId)?.name}</Text>,
		width: 160,
		sorter: (a, b) => {
			const categoryA = productCategoryMap?.get(a.categoryId)?.name || '';
			const categoryB = productCategoryMap?.get(b.categoryId)?.name || '';
			return categoryA.localeCompare(categoryB);
		},
	},
	{
		width: 150,
		responsive: ['sm'],
		fixed: 'right',
		render: (_, tobaccoProduct: TobaccoProduct) => <Actions id={tobaccoProduct.id} />,
	},
	{
		responsive: ['xs'],
		width: 50,
		fixed: 'right',
		render: (_, tobaccoProduct: TobaccoProduct) => <Actions id={tobaccoProduct.id} />,
	},
];

const TobaccoProductsScreen = () => {
	const [searchTerm, setSearchterm] = useState('');
	const { tobaccoProducts } = useTobaccoProducts();
	const { productCategories } = useProductCategories();
	const navigate = useNavigate();

	const productCategoryMap = useMemo(
		() => new Map(productCategories.map((productCategory) => [productCategory.id, productCategory])),
		[]
	);

	const displayedProducts = tobaccoProducts.filter((product) => {
		return product.name.toLowerCase().indexOf(searchTerm.toString().toLowerCase()) >= 0;
	});

	const columns = getColumns(productCategoryMap);

	return (
		<PageContainer
			title="Tobaksprodukter"
			extra={
				<Button onClick={() => navigate('create')} type="primary" icon={<PlusOutlined />}>
					Skapa tobaksprodukt
				</Button>
			}
		>
			<div className={styles.actionBar}>
				<Search
					value={searchTerm}
					onChange={(e) => setSearchterm(e.target.value)}
					size="large"
					placeholder="Sök efter namn"
					style={{ width: 400 }}
				/>
			</div>
			<Divider />

			<Table
				rowKey={(product) => product.id}
				locale={{
					emptyText: 'Inga tobaksprodukter',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="middle"
				columns={columns}
				dataSource={displayedProducts}
			/>
		</PageContainer>
	);
};

export default TobaccoProductsScreen;
