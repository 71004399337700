import styles from './styles/CreateNewsArticleScreen.module.scss';
import { Button, Card, Divider, Form, Input, Space, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import r from '../http';
import PageContainer from '../components/PageContainer';
import NewsArticleContext from '../state/NewsArticle';
import NewsArticle from '../models/NewsArticle';
import FormImage from '../components/form/Image';

const EditNewsArticleScreen = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { newsArticles, updateNewsArticle } = useContext(NewsArticleContext);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [newsArticle, setNewsArticle] = useState<NewsArticle>();
	const [form] = Form.useForm();

	useEffect(() => {
		(async () => {
			const savedNewsArticle = newsArticles.find((newsArticle) => newsArticle.id === parseInt(id!));
			if (savedNewsArticle) {
				setNewsArticle(savedNewsArticle);
				return;
			}

			const { data } = await r.get<NewsArticle>(`/news/${id}`);
			setNewsArticle(data);
		})();
	}, []);

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	if (!newsArticle) {
		return <h1>Loading ...</h1>;
	}

	const submit = async (form: any) => {
		const body = new FormData();
		body.append('id', newsArticle.id.toString());
		body.append('title', form.title);
		if (form.image[0] && form.image[0].originFileObj) {
			body.append('image', form.image[0].originFileObj);
		}
		body.append('body', form.body);

		try {
			setIsSubmitting(true);
			const response = await r.put<NewsArticle>(`/admin/news/${newsArticle.id}`, body);
			updateNewsArticle(response.data);
			notification.success({
				message: 'Nyhetsartikel uppdaterad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	return (
		<PageContainer back title="Uppdatera nyhetsartikel">
			<Form
				{...layout}
				labelAlign="left"
				form={form}
				size="large"
				onFinish={submit}
				initialValues={{
					title: newsArticle.title,
					body: newsArticle.body,
					image: newsArticle.imageUrl
						? [
								{
									uid: '-1',
									name: newsArticle.imageUrl.split('/').reverse()[0],
									status: 'done',
									url: newsArticle.imageUrl,
								},
						  ]
						: [],
				}}
			>
				<Card>
					<Form.Item label="Titel" name="title" rules={[{ required: true, message: 'Vänligen ange titel' }]}>
						<Input placeholder="Titel" />
					</Form.Item>
					<Divider />
					<FormImage label="Bild" name="image" removeable={false} />
					<Divider />
					<Form.Item label="Beskrivning" name="body" rules={[{ required: true, message: 'Vänligen ange beskrivning' }]}>
						<Input.TextArea rows={10} placeholder="Beskrivning" />
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<EditOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Uppdatera nyhetsartikel
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default EditNewsArticleScreen;
