import styles from './styles/EditTobaccoProductScreen.module.scss';
import { Button, Card, Form, Space, notification, Input, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import r from '../../http';
import PageContainer from '@components/PageContainer';
import useTobaccoProducts from '@hooks/tobacco-products';
import TobaccoProduct from '@models/TobaccoProduct';
import FormImage from '@components/form/Image';
import useProductCategories from '@hooks/product-category';

const EditTobaccoProductScreen = () => {
	const { tobaccoProducts, updateTobaccoProduct } = useTobaccoProducts();
	const { productCategories } = useProductCategories();
	const navigate = useNavigate();
	const { id } = useParams();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [form] = Form.useForm();

	const tobaccoProduct = useMemo(
		() => tobaccoProducts.find((tobaccoProduct) => tobaccoProduct.id === parseInt(id!)),
		[tobaccoProducts, id]
	);

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	if (!tobaccoProduct) {
		return <h1>Loading ...</h1>;
	}

	const submit = async (form: any) => {
		const body = new FormData();
		body.append('id', tobaccoProduct.id.toString());
		body.append('name', form.name);
		if (form.categoryId) {
			body.append('categoryId', form.categoryId);
		}
		if (form.image[0].originFileObj) {
			body.append('image', form.image[0].originFileObj);
		}
		try {
			setIsSubmitting(true);
			const response = await r.put<TobaccoProduct>(`/admin/tobacco-products/${tobaccoProduct.id}`, body);
			updateTobaccoProduct(response.data);
			notification.success({
				message: 'Tobaksprodukt uppdaterad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	return (
		<PageContainer back title="Uppdatera tobaksprodukt">
			<Form
				{...layout}
				labelAlign="left"
				form={form}
				size="large"
				onFinish={submit}
				initialValues={{
					name: tobaccoProduct.name,
					categoryId: tobaccoProduct.categoryId || null,
					image: tobaccoProduct.imageUrl
						? [
								{
									uid: '-1',
									name: tobaccoProduct.imageUrl.split('/').reverse()[0],
									status: 'done',
									url: tobaccoProduct.imageUrl,
								},
						  ]
						: [],
				}}
			>
				<Card>
					<Form.Item label="Namn" name="name" rules={[{ required: true, message: 'Vänligen ange namn' }]}>
						<Input placeholder="Namn" />
					</Form.Item>
					<FormImage label="Bild" name="image" removeable={false} required />
					<Form.Item label="Kategori" name="categoryId">
						<Select
							placeholder="Kategori"
							allowClear
							options={productCategories.map((category) => ({
								value: category.id,
								label: category.name,
							}))}
						/>
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<EditOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Uppdatera tobaksprodukt
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default EditTobaccoProductScreen;
