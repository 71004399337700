import React, { useContext, useEffect, useMemo, useState } from 'react';
import r from '../http';
import User from '@models/User';
import Role from '../models/Role';
import AuthContext from './Auth';

interface State {
	users: User[];
	addUser: (user: User) => void;
	updateUser: (user: User) => void;
	deleteUser: (id: number) => void;
}

const initialState: State = {
	users: [],
	addUser: () => {},
	updateUser: () => {},
	deleteUser: () => {},
};

const UserContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const UserContextProvider = ({ children }: Props) => {
	const [users, setUsers] = useState<User[]>(initialState.users);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (user && user.roles.find((r) => r === Role.Admin))
			(async () => {
				const response = await r.get<User[]>('/admin/admin-users');
				setUsers(response.data);
			})();
	}, [user]);

	const value: State = useMemo(() => {
		return {
			users,
			addUser: (user: User) => setUsers(users.concat(user)),
			updateUser: (user: User) => setUsers(users.map((u) => (u.id === user.id ? user : u))),
			deleteUser: (id: number) => setUsers(users.filter((u) => u.id !== id)),
		};
	}, [users]);

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContext;
