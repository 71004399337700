import { Modal, Table, Input, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { useContext, useEffect, useState } from 'react';
import Product from '../../../models/Product';
import ProductContext from '../../../state/Product';
import ListImage from '@components/table/ListImage';

const columns: ColumnsType<Product> = [
	{
		title: 'Bild',
		dataIndex: 'imageUrl',
		align: 'center',
		width: 110,
		render: (imageUrl) => <ListImage src={imageUrl} />,
	},
	{
		title: 'Namn',
		dataIndex: 'name',
	},
];

interface Props {
	open: boolean;
	storeId: number;
	existingProducts: number[];
	onClose: () => void;
	addStoreProducts: (body: { id: number; productIds: number[] }) => Promise<boolean>;
}

const AddProductsModal = ({ open, storeId, existingProducts, onClose, addStoreProducts }: Props) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [searchTerm, setSearchterm] = useState('');
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const { products } = useContext(ProductContext);

	useEffect(() => {
		setSelectedRowKeys([]);
	}, [open]);

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const submit = async () => {
		setIsSubmitting(true);
		const productIds = selectedRowKeys as number[];

		addStoreProducts({ id: storeId, productIds }).then((result) => {
			if (result === true) onClose();
		});

		setIsSubmitting(false);
	};

	const filteredProducts = products
		.filter((p) => !existingProducts.includes(p.id))
		.filter((p) => p.name.toLowerCase().includes(searchTerm.toLowerCase()));

	const rowSelection: TableRowSelection<Product> = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	return (
		<Modal
			title="Lägg till produkter i butik"
			centered
			open={open}
			onOk={submit}
			onCancel={onClose}
			width={1000}
			bodyStyle={{
				height: '80vh',
				overflow: 'scroll',
			}}
			okText={`Lägg till ${selectedRowKeys.length} produkter`}
			cancelText="Avbryt"
			cancelButtonProps={{
				size: 'large',
				type: 'text',
			}}
			okButtonProps={{
				disabled: !selectedRowKeys.length,
				size: 'large',
				loading: isSubmitting,
				icon: <PlusOutlined />,
			}}
		>
			<Input.Search
				value={searchTerm}
				onChange={(e) => setSearchterm(e.target.value)}
				size="large"
				placeholder="Sök efter produktnamn"
				style={{ width: 300 }}
			/>
			<Divider />
			<Table
				rowSelection={rowSelection}
				rowKey={(product) => product.id}
				locale={{
					emptyText: 'Inga produkter',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="middle"
				columns={columns}
				dataSource={filteredProducts}
			/>
		</Modal>
	);
};

export default AddProductsModal;
