import { Card, Form, Image, InputNumber, Modal, Select, Space, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import { VendingMachineProduct, VendingMachineProductResponse } from '@models/VendingMachineProducts';
import TobaccoProductContext from '../../../state/TobaccoProduct';
import r from '../../../http';

interface Props {
	open: boolean;
	vendingMachineId: number;
	existingProducts: VendingMachineProduct[];
	addProduct: (products: VendingMachineProductResponse) => void;
	onClose: () => void;
}

const layout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 14 },
};

const AddVendingMachineProductModal = ({ open, vendingMachineId, existingProducts, onClose, addProduct }: Props) => {
	const { tobaccoProducts } = useContext(TobaccoProductContext);
	const [searchTerm, setSearchTerm] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [form] = Form.useForm();

	const submit = async (formValues: any) => {
		setIsSubmitting(true);

		try {
			const body = new FormData();

			body.append('position', formValues.position);
			body.append('price', formValues.price);
			body.append('vendingMachineId', vendingMachineId.toString());
			body.append('tobaccoProductId', formValues.product);

			const response = await r.post<VendingMachineProductResponse>('/admin/vending-machine-products', body);

			addProduct(response.data);

			notification.success({
				message: 'Produkt tillagd till tobaksautomat!',
				placement: 'bottomRight',
				duration: 5,
			});
			form.resetFields();
			onClose();
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}

		setIsSubmitting(false);
	};

	return (
		<Modal
			title="Lägg till tobaksprodukt till tobaksautomat"
			centered
			open={open}
			onOk={() => form.submit()}
			onCancel={onClose}
			width={600}
			okText={`Lägg till produkt`}
			cancelText="Avbryt"
			cancelButtonProps={{
				size: 'large',
				type: 'text',
			}}
			okButtonProps={{
				size: 'large',
				loading: isSubmitting,
				icon: <PlusOutlined />,
				htmlType: 'submit',
			}}
		>
			<Form {...layout} labelAlign="left" form={form} size="large" onFinish={submit}>
				<Card>
					<Form.Item
						name="position"
						label="Position"
						rules={[
							{ required: true, message: 'Vänligen ange en position.' },
							{
								validator(_, value) {
									if (!existingProducts.map((p) => p.position).includes(value)) {
										return Promise.resolve();
									}
									return Promise.reject(new Error());
								},
								message: 'Positionen är upptagen.',
							},
						]}
						extra="Produktens position i tobaksautomaten."
					>
						<InputNumber style={{ width: 150 }} type="number" />
					</Form.Item>
					<Form.Item name="price" label="Pris" rules={[{ required: true, message: 'Vänligen ange ett pris.' }]}>
						<InputNumber addonAfter="SEK" min={0} style={{ width: 150 }} type="number" />
					</Form.Item>
					<Form.Item name="product" label="Produkt" rules={[{ required: true, message: 'Vänligen välj en produkt.' }]}>
						<Select
							showSearch
							allowClear
							filterOption={(input, option) =>
								option?.props['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{tobaccoProducts
								.filter((p) => !existingProducts.map((vmp) => vmp.tobaccoProduct).includes(p))
								.sort((a, b) => a.name.localeCompare(b.name))
								.map((p) => (
									<Select.Option value={p.id} key={p.id} data-name={p.name}>
										<Space>
											<Image src={p.imageUrl} width={30} />
											{p.name}
										</Space>
									</Select.Option>
								))}
						</Select>
					</Form.Item>
				</Card>
			</Form>
		</Modal>
	);
};

export default AddVendingMachineProductModal;
