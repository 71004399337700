import { message } from 'antd';

export const successMessage = (msg: String) => {
	message.success(msg);
};

export const errorMessage = (msg: unknown) => {
	message.error(`${msg}`);
};

export const warningMessage = (msg: String) => {
	message.warning(msg);
};
