import { Image, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useContext, useState } from 'react';
import ApplicationActions from './components/ApplicationActions';
import PageContainer from '../../components/PageContainer';
import VendingMachineApplicationContext from '../../state/VendingMachineApplication';
import VendingMachineApplication from '../../models/VendingMachineApplication';
import ReviewVendingModal from '../reviewVending/ReviewVendingModal';
import DateTime from '@helpers/date-time';

const VendingMachineApplicationsView = () => {
	const { vendingMachineApplications } = useContext(VendingMachineApplicationContext);
	const [reviewModalOpen, setReviewModalOpen] = useState(false);
	const [id, setId] = useState<number>(-1);

	const columns: ColumnsType<VendingMachineApplication> = [
		{
			title: 'ID',
			dataIndex: 'identificationImageUrl',
			key: 'identificationImageUrl',
			width: 150,
			render: (image) => <Image height={70} src={image} />,
		},
		{
			title: 'Personnummer',
			dataIndex: 'personalIdentificationNumber',
			key: 'personalIdentificationNumber',
			width: 175,
		},
		{
			title: 'Datum',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 175,
			render: (createdAt) => {
				if (!createdAt) return null;

				const dateTime = DateTime.fromISOUTC(createdAt);
				const date = dateTime.toFormat('YYYY-MM-DD HH:mm');
				const relative = dateTime.toRelative();

				return `${date} (${relative})`;
			},
		},
		{
			title: '',
			key: 'action',
			fixed: 'right',
			width: 210,
			render: (_, event) => (
				<ApplicationActions setId={() => setId(event.id)} openModal={() => setReviewModalOpen(true)} />
			),
		},
	];
	return (
		<PageContainer title="Tobaksansökningar">
			<Table
				rowKey={(application) => application.id}
				locale={{
					emptyText: 'Inga tobaksansökningar',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={false}
				scroll={{ x: '100%' }}
				size="middle"
				columns={columns}
				dataSource={vendingMachineApplications}
			/>
			<ReviewVendingModal id={id} open={reviewModalOpen} onClose={() => setReviewModalOpen(false)} />
		</PageContainer>
	);
};

export default VendingMachineApplicationsView;
