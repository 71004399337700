import { Button, Form, Input, Card, Space, Divider, Spin } from 'antd';
import { useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import styles from './styles/EditBannedAccountScreen.module.scss';
import { BannedUser } from '@models/BannedUser';
import TextArea from 'antd/lib/input/TextArea';
import { EditOutlined } from '@ant-design/icons';
import useBannedUsers from '@hooks/banned-users';

const EditBannedAccountScreen = () => {
	const { bannedUsers, editBannedUser } = useBannedUsers();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { id } = useParams();
	const navigate = useNavigate();

	const bannedUser = useMemo(() => bannedUsers.find((user) => user.id === parseInt(id!)), [bannedUsers, id]);

	const onSubmit = async (formData: BannedUser) => {
		if (!bannedUser) return;
		setIsSubmitting(true);
		editBannedUser({
			id: bannedUser.id,
			userId: bannedUser.userId,
			reason: formData.reason,
			userIdentifier: formData.userIdentifier,
		}).then((result) => {
			if (result === true) navigate(-1);
		});
		setIsSubmitting(false);
	};

	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 14 },
	};

	return (
		<PageContainer title="Uppdatera spärrat konto" back>
			<Spin spinning={!bannedUser} tip="Hämtar spärrat konto...">
				<Form labelAlign="left" {...layout} onFinish={onSubmit} size="large" initialValues={bannedUser}>
					<Card className={styles.inputs}>
						<Form.Item
							label="Personnummer/Telefonnummer"
							name="userIdentifier"
							extra="Telefonnummer måste vara med riktnummer och utan mellanslag. Kopiera telefonnummret som det är från events."
							rules={[{ required: true, message: 'Vänligen ange personnumer eller telefonnummer.' }]}
						>
							<Input />
						</Form.Item>
						<Divider />
						<Form.Item label="Anledning" name="reason">
							<TextArea size="large" style={{ height: 120 }} />
						</Form.Item>
					</Card>
					<div className={styles.actions}>
						<Form.Item>
							<Space size="middle">
								<Button onClick={() => navigate(-1)} type="text">
									Avbryt
								</Button>
								<Button loading={isSubmitting} type="primary" htmlType="submit" icon={<EditOutlined />}>
									Uppdatera spärrat konto
								</Button>
							</Space>
						</Form.Item>
					</div>
				</Form>
			</Spin>
		</PageContainer>
	);
};

export default EditBannedAccountScreen;
