import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import logo from '../../../assets/images/logo-white.png';
import PriceLabel, { PriceType } from '../PriceLabel';
import { OfferFormWithImage } from '../types';
import createShadowRoot from '../ShadowRoot';
import { styleContent } from './styles/styleContent';
import ResizableTextWrapper from '@components/resizeable-text/ResizableTextWrapper';
import { previewUpdateDelay } from '../../functions/print';
import { getImage } from '../../functions/getImage';

type Props = {
	iFrameId: string;
} & OfferFormWithImage;

const Style = () => <style>{styleContent}</style>;

const StoreOfferPrintWithImagePreview = ({
	preTitle,
	title,
	product,
	extra,
	show24FoodLogo,
	type,
	price,
	unit,
	save,
	iFrameId,
	priceShowDecimals,
	saveShowDecimals,
	first,
	second,
	quantity,
	singlePrice,
	image,
	imageScale,
}: Props) => {
	const previewRef = useRef<HTMLDivElement | null>(null);
	const imageRef = useRef<HTMLImageElement | null>(null);
	const imageContainerRef = useRef<HTMLDivElement | null>(null);
	const [imageSize, setImageSize] = useState({ height: 0, width: 0 });

	useLayoutEffect(() => {
		const iframe = document.getElementById(iFrameId) as HTMLIFrameElement | null;
		if (iframe) {
			const iframeDocument = iframe.contentDocument;
			if (iframeDocument) {
				const iframeBody = iframeDocument.body;
				if (iframeBody) {
					setTimeout(() => {
						iframeBody.innerHTML = previewRef.current?.outerHTML || '';
					}, previewUpdateDelay);
				}
			}
		}
	});

	function calculateImageSize() {
		const containerHeight = imageContainerRef.current?.clientHeight || 0;
		const containerWidth = imageContainerRef.current?.clientWidth || 0;
		const imageHeight = imageRef.current?.naturalHeight || 0;
		const imageWidth = imageRef.current?.naturalWidth || 0;
		let newImageHeight = 0;
		let newImageWidth = 0;

		if (imageWidth > imageHeight) {
			newImageWidth = containerWidth * 0.8;
			newImageHeight = newImageWidth * (imageHeight / imageWidth);
		} else {
			newImageHeight = containerHeight * 0.8;
			newImageWidth = newImageHeight * (imageWidth / imageHeight);
		}

		setImageSize({
			height: newImageHeight,
			width: newImageWidth,
		});
	}

	useEffect(() => {
		calculateImageSize();
	}, [product, extra]);

	function onImageLaod() {
		calculateImageSize();
	}

	return (
		<div ref={previewRef} className="paper">
			<Style />
			<div className="body">
				<div ref={imageContainerRef} className="image-contaienr">
					<div style={{ ...imageSize, position: 'relative' }}>
						{image && (
							<>
								<img
									ref={imageRef}
									onLoad={onImageLaod}
									src={getImage(image)}
									style={{ ...imageSize, transform: `scale(${imageScale})` }}
								/>
								<div className="price-container">
									{type === PriceType.Price && (
										<PriceLabel type="price" price={price} showPriceDecimals={priceShowDecimals} unit={unit} />
									)}
									{type === PriceType.PieceForPiece && (
										<PriceLabel type="price-for-piece" first={first} second={second} singlePrice={singlePrice} />
									)}
									{type === PriceType.AmountForPrice && (
										<PriceLabel
											type="amount-for-price"
											quantity={quantity}
											price={price}
											showPriceDecimals={priceShowDecimals}
											save={save}
											showSaveDecimals={saveShowDecimals}
										/>
									)}
								</div>
							</>
						)}
					</div>
				</div>
				<div className="product">
					<h1 className="product__name">{product}</h1>
					{!!extra && <pre className="product__extra">{extra}</pre>}
				</div>
			</div>
			<div className="footer">
				<div className="footer__line"></div>
				<div className="footer__body">
					<div className="footer__content">
						<ResizableTextWrapper
							textComponent={<h2 className="pre-title">{preTitle}</h2>}
							containerSize="90%"
							initialFontSize="70px"
						/>
						<ResizableTextWrapper
							textComponent={<h1 className="title">{title}</h1>}
							containerSize="90%"
							initialFontSize="110px"
						/>
						{show24FoodLogo && <img className="logo" src={logo} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default createShadowRoot(StoreOfferPrintWithImagePreview);
