import { Button, Spin, Divider, Card, Row, Col, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '@components/PageContainer';
import UserContext from '../../state/User';
import { useContext } from 'react';
import { getSwedishRoleName } from '@helpers/role';
import StoreContext from '../../state/Store';
import Role from '../../models/Role';

const { Text } = Typography;

const UserDetailsScreen = () => {
	const { users } = useContext(UserContext);
	const { stores } = useContext(StoreContext);
	const navigate = useNavigate();
	const id = useParams().id || '0';

	const user = users.find((user) => user.id === parseInt(id));

	const labelLayout = {
		md: 6,
		sm: 24,
		xs: 24,
	};

	const valueLayout = {
		md: 12,
		sm: 24,
		xs: 24,
	};

	return (
		<PageContainer
			title="Användar detaljer"
			back
			extra={[<Button key="edit" onClick={() => navigate('edit')} type="primary" icon={<EditOutlined />} />]}
		>
			<Spin spinning={!user} tip="Hämtar användare...">
				<Card key={user?.id}>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Förnamn
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{user?.firstName}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Efternamn
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{user?.lastName}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Email
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{user?.email}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Roll
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{user ? getSwedishRoleName(user.roles[0]) : ''}</Text>
						</Col>
						{user?.roles.includes(Role.FranchiceAdmin) ? <Divider /> : <></>}
					</Row>
					{user?.roles.includes(Role.FranchiceAdmin) ? (
						<Row>
							<Col {...labelLayout}>
								<Text strong type="secondary">
									Butiker
								</Text>
							</Col>
							<Col {...valueLayout}>
								<Text>
									{stores
										.filter((s) => user?.franchiceStoreIds.includes(s.id))
										.map((s) => s.name)
										.join(', ')}
								</Text>
							</Col>
						</Row>
					) : (
						<></>
					)}
				</Card>
			</Spin>
		</PageContainer>
	);
};

export default UserDetailsScreen;
