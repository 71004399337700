import type { ColumnsType } from 'antd/lib/table';
import Actions from '../components/Actions';
import User from '@models/User';
import { getSwedishRoleName } from '@helpers/role';
import Store from '@models/Store';
import { Link } from 'react-router-dom';

export const getColumns = (storeMap: Map<number, Store>): ColumnsType<User> => [
	{
		title: 'Förnamn',
		dataIndex: 'firstName',
		key: 'firstName',
		width: 160,
		sorter: (a, b) => a.firstName.localeCompare(b.firstName),
	},
	{
		title: 'Efternamn',
		dataIndex: 'lastName',
		key: 'lastName',
		width: 160,
		sorter: (a, b) => a.lastName.localeCompare(b.lastName),
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
		width: 160,
		sorter: (a, b) => a.email.localeCompare(b.email),
	},
	{
		title: 'Roll',
		dataIndex: 'role',
		key: 'role',
		render: (_, user: User) => <>{getSwedishRoleName(user.roles[0])}</>,
		sorter: (a, b) => getSwedishRoleName(a.roles[0]).localeCompare(getSwedishRoleName(b.roles[0])),
		width: 125,
	},
	{
		title: 'Butiker',
		dataIndex: 'stores',
		key: 'stores',
		render: (_, user: User) => (
			<>
				{user.franchiceStoreIds.map((storeId, i) => {
					const store = storeMap.get(storeId);
					const lastStore = user.franchiceStoreIds.length === i + 1;
					const ending = lastStore ? null : ', ';

					if (!store) return <span>Okänd{ending}</span>;

					return (
						<span key={storeId}>
							<Link to={`/stores/${store.id}`}>{store.name}</Link>
							{ending}
						</span>
					);
				})}
			</>
		),
		width: 100,
	},
	{
		width: 150,
		responsive: ['sm'],
		fixed: 'right',
		render: (_, user: User) => <Actions id={user.id} />,
	},
	{
		responsive: ['xs'],
		width: 50,
		fixed: 'right',
		render: (_, user: User) => <Actions id={user.id} />,
	},
];
