import { useContext, useEffect, useState } from 'react';
import { Button, Card, Form, Input, Modal, Space, Switch, notification } from 'antd';
import PageContainer from '../components/PageContainer';
import { EditOutlined } from '@ant-design/icons';
import styles from './styles/AnnouncementScreen.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import r from '../http';
import Announcement from '../models/Announcement';
import AnnouncementContext from '../state/Announcement';

const EditAnnouncementScreen = () => {
	const { announcements, updateAnnouncement } = useContext(AnnouncementContext);

	const [announcement, setAnnouncement] = useState<Announcement>();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const navigate = useNavigate();
	const id = useParams().id!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
	const [form] = Form.useForm();

	useEffect(() => {
		(async () => {
			const savedAnnouncements = announcements.find((announcement) => announcement.id === parseInt(id));
			if (savedAnnouncements) {
				setAnnouncement(savedAnnouncements);
				return;
			}

			const { data } = await r.get<Announcement>(`/admin/announcements/${id}`);
			setAnnouncement(data);
		})();
	}, [id, announcements]);

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	if (!announcement) {
		return <h1>Loading ...</h1>;
	}

	const submit = async (form: any) => {
		try {
			setIsSubmitting(true);
			const response = await r.put<Announcement>(`/admin/announcements/${announcement.id}`, {
				id: announcement.id,
				title: form.title,
				message: form.message,
				isActive: form.isActive,
			});
			updateAnnouncement(response.data);

			notification.success({
				message: 'Meddelande uppdaterat!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	const confirm = (formValues: any) => {
		const confirmationText = `Är du säker på att du vill aktivera detta meddelande? Meddelandet kommer att visas för alla i appen.`;
		if (formValues.isActive) {
			Modal.confirm({
				title: 'Aktivera meddelande',
				content: confirmationText,
				okText: 'Ja, aktivera',
				cancelText: 'Avbryt',
				onOk: () => submit(formValues),
			});
		} else {
			submit(formValues);
		}
	};

	return (
		<PageContainer title="Uppdatera viktigt meddelande" back>
			<Form
				{...layout}
				labelAlign="left"
				form={form}
				size="large"
				onFinish={confirm}
				initialValues={{
					title: announcement.title,
					message: announcement.message,
					isActive: announcement.isActive,
				}}
			>
				<Card>
					<Form.Item label="Titel" name="title" rules={[{ required: true, message: 'Vänligen ange titel' }]}>
						<Input placeholder="Titel" />
					</Form.Item>
					<Form.Item label="Innehåll" name="message" rules={[{ required: true, message: 'Vänligen ange innehåll' }]}>
						<Input placeholder="Innehåll" />
					</Form.Item>
					<Form.Item label="Aktivera" name="isActive" valuePropName="checked">
						<Switch />
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button type="primary" htmlType="submit" icon={<EditOutlined />} loading={isSubmitting}>
								Uppdatera viktigt meddelande
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default EditAnnouncementScreen;
