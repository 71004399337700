import React, { useEffect, useMemo, useState } from 'react';
import Product from '../models/Product';
import r from '../http';

interface State {
	products: Product[];
	addProduct: (product: Product) => void;
	updateProduct: (product: Product) => void;
	deleteProduct: (id: number) => void;
}

const initialState: State = {
	products: [],
	addProduct: () => {},
	updateProduct: () => {},
	deleteProduct: () => {},
};

const ProductContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const ProductContextProvider = ({ children }: Props) => {
	const [products, setProducts] = useState<Product[]>([]);

	useEffect(() => {
		(async () => {
			const response = await r.get<Product[]>('/admin/products');
			setProducts(response.data);
		})();
	}, []);

	const value: State = useMemo(() => {
		return {
			products,
			addProduct: (product: Product) => setProducts(products.concat(product)),
			updateProduct: (product: Product) => setProducts(products.map((p) => (p.id === product.id ? product : p))),
			deleteProduct: (id: number) => setProducts(products.filter((p) => p.id !== id)),
		};
	}, [products]);

	return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
};

export default ProductContext;
