import { Checkbox, Image, Space, Typography } from 'antd';
import printGuide from '../assets/print-guide.png';

function PrintHelp() {
  return (
    <Space align="start">
      <div style={{ paddingTop: 10 }}>
        <Typography.Paragraph>
          För att reklambladet ska se så bra som möjligt ut när det skrivs ut behöver du göra några inställningar.{' '}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <b>OBS</b> Inställningarna kan skilja sig något mellan olika webbläsare.
        </Typography.Paragraph>
        <Typography.Paragraph>
          <b>Inställningar:</b>
        </Typography.Paragraph>
        <ul>
          <li>Sätt marginaler till inga</li>
          <li>Avaktivera sidhuvuden och sidfötter</li>
          <li>Aktivera bakgrundsgrafik</li>
        </ul>
        <br />
      </div>
      <div>
        <Image width={300} src={printGuide} />
      </div>
    </Space>
  );
}

export default PrintHelp;
