import { Button, Card, Col, Divider, Image, Modal, notification, Row, Spin, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import Purchase from '../../models/Purchase';
import PurchaseContext from '../../state/Purchase';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import r from '../../http';
import { captureMessage } from '@sentry/react';
import DateTime from '@helpers/date-time';

interface Props {
	id: number;
	open: boolean;
	onClose: () => void;
}

const { Text } = Typography;

const ReviewPurchaseView = ({ id, onClose }: Props) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { purchases } = useContext(PurchaseContext);
	const [application, setApplication] = useState<Purchase>();

	useEffect(() => {
		let foundApplication = purchases.find((a) => a.id === id) as Purchase;
		if (!foundApplication) {
			notification.error({ message: 'Ogiltigt ID', description: 'Ingen ansökan med det angivna ID:t kunde hittas.' });
			onClose();
			return;
		}
		setApplication(foundApplication);
	}, []);

	const labelLayout = {
		md: 6,
		sm: 24,
		xs: 24,
	};

	const valueLayout = {
		md: 12,
		sm: 24,
		xs: 24,
	};

	const grant = async () => {
		if (!application) return;
		captureMessage(`Accept purchase request with id: ${id}`);
		try {
			setIsSubmitting(true);
			await r.post(`/admin/vending-machine/purchases/${id}/accept`, {});
			notification.success({
				message: 'Ansökan godkänd!',
				placement: 'bottomRight',
				duration: 5,
			});
		} catch (e) {
			notification.error({ message: 'Felmeddelande', description: `Error: ${e}` });
		}
		setIsSubmitting(false);
		onClose();
	};

	const deny = async () => {
		if (!application) return;
		captureMessage(`Deny purchase request with id: ${id}`);
		try {
			setIsSubmitting(true);
			await r.post(`/admin/vending-machine/purchases/${id}/deny`, {});
			notification.success({
				message: 'Ansökan avböjd!',
				placement: 'bottomRight',
				duration: 5,
			});
		} catch (e) {
			notification.error({ message: 'Felmeddelande', description: `Error: ${e}` });
		}
		setIsSubmitting(false);
		onClose();
	};

	if (!application)
		return (
			<>
				<p>Error: application not found...</p>
			</>
		);

	return (
		<>
			<Spin spinning={!application} tip="Hämtar nyhet...">
				<Card>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Skåp
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{application.vendingMachine?.name}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Personnummer
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text style={{ fontSize: 28 }}>{application.personalIdentificationNumber}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								ID
							</Text>
						</Col>
						<Col>
							<Image
								style={{ objectFit: 'contain' }}
								height={300}
								src={application.application.identificationImageUrl}
							/>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Datum
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{`${DateTime.fromISOUTC(application.createdAt).toFormat('YYYY-MM-DD HH:mm')} (${DateTime.fromISOUTC(
								application.createdAt
							).toRelative()})`}</Text>
						</Col>
					</Row>
				</Card>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Button loading={isSubmitting} size="large" type="primary" danger onClick={deny}>
						<CloseOutlined />
						Avböj
					</Button>
					<Button loading={isSubmitting} size="large" type="primary" onClick={grant}>
						<CheckOutlined />
						Godkänn
					</Button>
				</div>
			</Spin>
		</>
	);
};

const ReviewPurchaseModal = ({ id, open, onClose }: Props) => {
	return (
		<Modal title="Granska förfrågan" open={open} centered destroyOnClose footer={<></>} onCancel={onClose}>
			<ReviewPurchaseView id={id} open={open} onClose={() => onClose()} />
		</Modal>
	);
};

export default ReviewPurchaseModal;
