import ReactDOM, { Root } from 'react-dom/client';
import { ComponentType, useEffect, useId, useRef } from 'react';

const createShadowRoot = (Component: ComponentType<any>) => (props: any) => {
	const root = useRef<Root | null>(null);
	const id = useId();

	useEffect(() => {
		if (!root.current) {
			const el = document.getElementById(id);
			if (!el) return;

			const shadowRoot = el?.attachShadow({ mode: 'open' });
			if (!shadowRoot) return;

			el.style.height = '100%';
			el.style.width = '100%';
			root.current = ReactDOM.createRoot(shadowRoot);
		}

		root.current.render(<Component {...props} />);
	});

	return <div id={id} />;
};

createShadowRoot.displayName = 'createShadowRoot';

export default createShadowRoot;
