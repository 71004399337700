import { Button, Divider, Table, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import styles from './styles/BannedAccountsScreen.module.scss';
import { useNavigate } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import getColumns from './components/Columns';
import useBannedUsers from '@hooks/banned-users';

const { Search } = Input;

const BannedAccountsScreen = () => {
	const { bannedUsers, removeBannedUser } = useBannedUsers();
	const [searchTerm, setSearchTerm] = useState('');

	const navigate = useNavigate();

	const displayedData = bannedUsers.filter((user) => user.userIdentifier.includes(searchTerm.toLowerCase()));

	const onDelete = (userIdentifier: string) => {
		removeBannedUser({ userIdentifier });
	};

	return (
		<PageContainer
			title="Spärrade konton"
			extra={
				<Button onClick={() => navigate('new')} type="primary" icon={<PlusOutlined />}>
					Spärra konto
				</Button>
			}
		>
			<div className={styles.actionBar}>
				<Search
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					size="large"
					placeholder="Sök"
					style={{ width: 400 }}
				/>
			</div>
			<Divider />
			<Table
				rowKey={(bannedUser) => bannedUser.userId}
				locale={{
					emptyText: 'Inga spärrade konton',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="large"
				columns={getColumns(onDelete)}
				dataSource={displayedData}
			/>
		</PageContainer>
	);
};

export default BannedAccountsScreen;
