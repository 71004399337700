import { useEffect, useState } from 'react';
import r from '../http';

const useFetch = <T = unknown>(url?: string, onSuccess?: (data: T) => void, onError?: (e: unknown) => void) => {
	const [isFetching, setIsFetching] = useState(false);
	const [error, setError] = useState(false);
	const [data, setData] = useState<T | null>(null);

	useEffect(() => {
		if (!url) return;

		(async () => {
			setIsFetching(true);
			try {
				const response = await r.get<T>(url);
				setData(response.data);
				if (onSuccess) onSuccess(response.data);
			} catch (e) {
				setError(true);
				if (onError) onError(e);
			}
			setIsFetching(false);
		})();
	}, [url]);

	return {
		isFetching,
		error,
		data,
	};
};

export default useFetch;
